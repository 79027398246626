import React from "react";

// components
import { SuiTypography, SuiInput, SuiButton } from "components";

// mui imports
import { Box } from "@mui/material";

import EntityForm from "./EntityForm";

// types
import { tab_PT } from ".";

const EditEntity = ({ handleClose }: tab_PT) => {
  const [isSearched, setSearched] = React.useState<boolean>(false);

  const handleSearch = () => {
    // do search
    setSearched(true);
  };

  return (
    <Box mt={2.5}>
      <SuiTypography variant="body2" color="light" fontWeight="bold" ml={0.5}>
        Edit Entity
      </SuiTypography>

      {/* search field */}
      {!isSearched && (
        <Box mt={1.5} width="100%">
          <SuiInput placeholder="Search an Entity" />
        </Box>
      )}

      {/* form content */}
      <Box mt={2.5}>
        {isSearched && <EntityForm />}

        {/* buttons */}
        <Box
          mt={2.5}
          display={"flex"}
          justifyContent="flex-end"
          sx={{
            "& button:not(:first-of-type)": {
              ml: 1.5
            }
          }}>
          <SuiButton color="light" size="small" circular onClick={handleClose}>
            Cancel
          </SuiButton>
          {isSearched && (
            <SuiButton color="light" size="small" circular onClick={() => setSearched(false)}>
              Back
            </SuiButton>
          )}
          <SuiButton color="light" size="small" circular onClick={handleSearch}>
            {isSearched ? "Save" : "Search"}
          </SuiButton>
        </Box>
      </Box>
    </Box>
  );
};

export default EditEntity;
