import React from "react";

import { Tooltip } from "@mui/material";

// types
import { order_T, row_PT, displayTableData_T, booleanValues } from "./types";

export const CalcPadding = (arr: any, key: number) => {
  let pl, pr;

  if (key === 0) {
    pl = 3;
    pr = 3;
  } else if (key === arr.length - 1) {
    pl = 3;
    pr = 3;
  } else {
    pl = 1;
    pr = 1;
  }

  return { pl, pr };
};

export const handleCheckRow = (name: string, arr: readonly string[], handleCheck: any) => {
  const selectedIndex = arr.indexOf(name);
  let newSelected: readonly string[] = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(arr, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(arr.slice(1));
  } else if (selectedIndex === arr.length - 1) {
    newSelected = newSelected.concat(arr.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(arr.slice(0, selectedIndex), arr.slice(selectedIndex + 1));
  }

  // rest behaviour
  handleCheck(newSelected);
};

export const handleSelectRow = (name: string, arr: readonly string[], handleRow: any, row: row_PT) => {
  const newSeleted = arr[0] === name ? [] : [name];

  // rest behaviour
  handleRow(newSeleted, row);
};

export const isSelected = (name: string, arr: readonly string[]) => arr.indexOf(name) !== -1;

export const handleCheckedAll = (e: React.ChangeEvent<HTMLInputElement>, rows: row_PT[], handleCheckAll: any) => {
  if (e.target.checked) {
    const newSelecteds = rows.map((r: row_PT) => `rowId_${r.id}`);
    handleCheckAll(newSelecteds);
    return;
  }

  handleCheckAll([]);
};

// sort methods
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  else if (b[orderBy] > a[orderBy]) return 1;

  return 0;
}

export function getComparator<Key extends keyof any>(
  order: order_T,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis: any = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

export const DisplayTableData = ({ value, isBoolean, limitLength }: displayTableData_T) => {
  const returnData = () => {
    if (!!isBoolean) {
      const { trueVal, falseVal } = isBoolean as booleanValues;
      if (value) return trueVal;
      else return falseVal;
    } else if (!!limitLength) {
      const isToolTip = `${value}`.length > limitLength;
      const txt = `${value}`.slice(0, limitLength);
      return isToolTip ? (
        <Tooltip title={value} placement="right">
          <span>{txt}</span>
        </Tooltip>
      ) : (
        txt
      );
    }

    // default
    return value;
  };

  return <>{returnData()}</>;
};
