import emailjs from "@emailjs/browser";

import { toastSuccess, toastError } from "utils/toast";

export default async function sendEmail(templateId, formData, succesMsg = "Sent successfully") {
  const isSent = new Promise((resolve, reject) => {
    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, templateId, formData, process.env.REACT_APP_EMAILJS_KEY).then(
      () => {
        toastSuccess(succesMsg);
        resolve("sent");
      },
      (error) => {
        toastError(error.text);
        reject('Error Occured')
      }
    );
  });

  return await isSent;
}
