import React from "react";

// components
import { SuiBox, SuiTypography } from "components";

import { theme_types } from "assets/theme";

// static colors
import colors from "assets/theme/base/colors";

const Footer = () => {
  const { white } = colors;

  return (
    <SuiBox
      component="footer"
      textAlign="center"
      sx={({
        palette: {
          staticColors: { darkBlue }
        }
      }: theme_types) => ({
        background: darkBlue
      })}>
      <SuiTypography variant={"body3"} sx={{ color: white.main }}>
        &#169; 2022 ADAKAS. All rights reserved.
      </SuiTypography>
    </SuiBox>
  );
};

export default Footer;
