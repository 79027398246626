// layout
import StaticLayout from "layouts/static/StaticLayout";

import React from "react";

// images
import { dasboardImg, carbonReportingImg, lineChartImg, pieChartImg, adakasEntityImg, adakasLineChartImg } from "assets/images";

// components
import { SuiBox, SuiTypography, SuiButton } from "components";
import { Grid, Stack, Box } from "@mui/material";
import ServiceCard from "layouts/static/components/ServiceCard";
import ContactForm from "layouts/static/components/ContactForm";

import { theme_types } from "assets/theme";

// modals
import AuthModal from "extraComp/modals/auth";

// static colors
import colors from "assets/theme/base/colors";
import { DashboardsEnums } from "enums/dashboards.enum";

const HomePage = () => {
  const { white, dark, info, text } = colors;
  const minWidth = 450;

  // use states
  const [open, setOpen] = React.useState<boolean>(false);
  const [width, setWidth] = React.useState<Number>(window.innerWidth);
  const [currentDashboard, setCurrentDashboardOpen] = React.useState<DashboardsEnums>(DashboardsEnums.ADAKAS_DASHBOARD);

  React.useEffect(() => {
    const getWindowWidth = () => setWidth(window.innerWidth > minWidth ? window.innerWidth : minWidth);
    // add listner
    window.addEventListener("resize", getWindowWidth);
    // remove listner
    return () => window.removeEventListener("resize", getWindowWidth);
  }, []);

  // images
  const bannerImg = `https://images.pexels.com/photos/157040/pexels-photo-157040.jpeg?auto=compress&w=${width}`,
    carbonReportingBgImg = `https://images.pexels.com/photos/159397/solar-panel-array-power-sun-electricity-159397.jpeg?auto=compress&w=${width}`,
    kycBgImg = `https://images.pexels.com/photos/9951077/pexels-photo-9951077.jpeg?auto=compress&w=${width}`,
    aboutimg = `https://images.pexels.com/photos/3861987/pexels-photo-3861987.jpeg?auto=compress&w=${width}`,
    contactImg = `https://images.pexels.com/photos/8204409/pexels-photo-8204409.jpeg?auto=compress&w=${width}`,
    missionImg = `https://images.pexels.com/photos/7245368/pexels-photo-7245368.jpeg?auto=compress&w=${width}`,
    solarpanelImg = `https://static.wixstatic.com/media/e1e29a_8eacc2d1e55446f8a1b2ca9d57274a6d~mv2.png/v1/fill/w_952,h_931,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/e1e29a_8eacc2d1e55446f8a1b2ca9d57274a6d~mv2.png`,
    opporImg = `https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress&w=${width}`;
    

  const handleDashb_navigation = (dashboard?: string) => {
    if (dashboard === DashboardsEnums.CARBON_TOOL) setCurrentDashboardOpen(DashboardsEnums.CARBON_TOOL);
    setOpen((prevOpen) => !prevOpen);
  };



  return (
    <React.Fragment>
      <StaticLayout>
        {/* banner */}
        <SuiBox
          id="home_section"
          sx={{
            backgroundImage: `url(${bannerImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            clipPath: `polygon(100% 0, 100% ${90}%, 0 97%, 0 0)`,
            height: "80vh",
            position: "relative"
          }}>
          <SuiBox
            sx={({
              palette: {
                staticColors: { darkBlue }
              },
              functions: { rgba }
            }: theme_types) => ({
              background: rgba(darkBlue, 0.8),
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            })}>
            <Box mr={{ sm: 8 }} width={{ xs: "100%", md: "38%" }}>
              <SuiTypography variant={"h2"} p={1} textAlign={{ xs: "center", sm: "right" }} sx={{ color: white.main }}>
                Innovating Regulatory Solutions
              </SuiTypography>
              <Box my={1.5} textAlign={{ xs: "center", sm: "right" }}>
                {/* <SuiButton
                size="small"
                circular
                onClick={handleDashb_navigation}
                setFixedColor="white"
                data-cy="go_to_dashboard">
                Go to Dashboard
              </SuiButton> */}
              </Box>
            </Box>
          </SuiBox>
        </SuiBox>

        {/* features */}
        <SuiBox id="Features_section" display="flex" alignItems="center" flexDirection="column">
          <Grid container spacing={2} p={{ xs: 2, lg: 4 }}>
            <Grid item xs={12} md={6}>
              <Box
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                height={"100%"}
                px={{ xs: 2, lg: 5 }}
                gap={3}
                mb={{ xs: 4 }}>
                <SuiBox
                  component={"a"}
                  target="_blank"
                  overflow={"hidden"}
                  borderRadius="sm"
                  shadow="sm"
                  height={{ xs: "30vh", lg: "38vh" }}>
                  <img src={carbonReportingImg} alt="feature_img" style={{ width: "100%", height: "100%" }} />
                </SuiBox>
                <SuiBox overflow={"hidden"} borderRadius="sm" shadow="sm" height={{ xs: "30vh", lg: "45vh" }}>
                  <img src={dasboardImg} alt="feature_img" style={{ width: "100%", height: "100%" }} />
                </SuiBox>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box textAlign="center" px={2}>
                <SuiTypography variant="h6" sx={{ color: info.main }}>
                  SERVICES
                </SuiTypography>
                <SuiTypography variant="h5" mt={1} sx={{ color: dark.main }}>
                  Our Featured Services That We Provide
                </SuiTypography>
              </Box>

              <Grid container spacing={3} px={3} py={5}>
                <Grid item xs={12} lg={6}>
                  <ServiceCard imgSrc="https://img.icons8.com/ultraviolet/344/module.png" title="Carbon Reporting" descp="" />
                  <SuiTypography variant="body3" sx={{ color: text.main }}>
                    We have built a SaaS tool to help with your Sustainability initiatives and to help achieve your Net-Zero
                    goals. This is a vital part of global ESG policy and we can assist with the reporting requirements around
                    this. <a href="#contact_section">Book a demo</a> with our team and have a look at our Carbon Reporting
                    dashboard.
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ServiceCard imgSrc="https://img.icons8.com/ultraviolet/344/module.png" title="KYC" descp="" />
                  <SuiTypography variant="body3" sx={{ color: text.main }}>
                    We offer a suite of KYC products aimed at helping you fight financial crime. This includes Sanctions
                    Screening, PEP Lists and Adverse Media searches utilising AI-driven detection technology to help you manage
                    your risk, all via a single dashboard. <a href="#contact_section">Get in touch</a> with us to learn more about
                    our solutions and book a demo.
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ServiceCard
                    imgSrc="https://img.icons8.com/ultraviolet/344/module.png"
                    title="Compliance Advisory Services"
                    descp=""
                  />
                  <SuiTypography variant="body3" sx={{ color: text.main }}>
                    We can help you manage your day-to-day compliance, or provide specialist compliance services such as training,
                    FCA application support or 'deep dive' reviews of your business processes to help you understand your
                    compliance risk and obligations. Please <a href="#contact_section">contact us</a> for more information.
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ServiceCard imgSrc="https://img.icons8.com/ultraviolet/344/module.png" title="Bespoke Products" descp="" />
                  <SuiTypography variant="body3" sx={{ color: text.main }}>
                    If you are looking to develop a technical solution for your business,{" "}
                    <a href="#contact_section">contact us</a> to find out how we can be of assistance. Our team of experts will
                    work with you to identify your system requirements and determine the best solutions to help solve your
                    specific regulatory requirements.
                  </SuiTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>

        {/* about */}
        <SuiBox
          id="about_section"
          sx={{
            backgroundImage: `url(${aboutimg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "70vh",
            position: "relative"
          }}>
          <SuiBox
            sx={({
              palette: {
                staticColors: { darkGray }
              },
              functions: { rgba }
            }: theme_types) => ({
              background: rgba(darkGray, 0.85),
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            })}>
            <Box mr={{ xs: 1.5, sm: 8 }} width={{ xs: "85%", sm: "50%", md: "38%" }}>
              <Box my={1.5}>
                <SuiTypography variant={"h3"} sx={{ color: white.main }}>
                  Who We Are
                </SuiTypography>
              </Box>
              <Box my={1.5}>
                <SuiTypography variant={"body3"} textAlign="right" sx={{ color: white.main }}>
                  Our founders have over 50 years’ combined experience in Technology, Compliance and Risk Management, having
                  worked and built solutions at leading firms such as Goldman Sachs, JP Morgan, Bank of America, Channel 4 and Sky
                  News. Our team of experts bring this wealth of experience to design and develop leading services that empower
                  your firm to contribute to solutions to world-wide problems, whilst at the same time supporting your business
                  needs.
                </SuiTypography>
              </Box>
            </Box>
          </SuiBox>
        </SuiBox>

        {/* mission */}
        <SuiBox
          id="mission_section"
          display="flex"
          alignItems="center"
          flexDirection="column"
          py={7}
          sx={({
            palette: {
              staticColors: { darkBlue }
            },
            functions: { rgba }
          }: theme_types) => ({
            background: rgba(darkBlue, 0.92)
          })}>
          <Grid container spacing={2} p={{ xs: 2, lg: 5 }}>
            <Grid item xs={12} md={6}>
              <Box textAlign="left" px={2}>
                <SuiTypography variant="h6" sx={{ color: info.main }}>
                  MISSIONS, VALUES & VISION
                </SuiTypography>
                <SuiTypography variant="h5" mt={1} sx={{ color: white.main }}>
                  Our mission, values and vision
                </SuiTypography>
              </Box>

              <Grid container spacing={3} px={3} py={5} sx={{ "& > div > div": { "& p,& span,& li": { color: white.main } } }}>
                <Grid item xs={12} lg={6}>
                  <ServiceCard
                    imgSrc="https://img.icons8.com/ultraviolet/344/module.png"
                    title="Mission"
                    descp="We innovate, using deep tech to build secure, reliable and practical solutions to manage regulatory obligations."
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ServiceCard
                    imgSrc="https://img.icons8.com/ultraviolet/344/module.png"
                    title="Vision"
                    descp="To provide fully automated end-to-end solutions that empower firms to solve world-wide problems."
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ServiceCard
                    imgSrc="https://img.icons8.com/ultraviolet/344/module.png"
                    title="Values"
                    descp={[
                      "Integrity - we believe in honesty and openness",
                      "Collaboration - everyone’s opinion is valued and considered",
                      "Results - prioritising client needs and empowering them to manage their regulatory requirements confidently",
                      "Diversity - many viewpoints, many solutions: our people are our greatest assets",
                      "Innovation - striving to stay at the forefront of technology and regulation"
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={6}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display={"flex"} alignItems="center" height={"100%"} px={{ xs: 2, lg: 5 }}>
                <Box
                  sx={{
                    backgroundImage: `url(${missionImg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    clipPath: `polygon(0 5%, 100% 0, 100% 95%, 0% 100%)`,
                    width: "100%"
                  }}
                  height={{ xs: "30vh", lg: "45vh" }}
                />
              </Box>
            </Grid>
          </Grid>
        </SuiBox>

        {/* carbon reporting */}
        <SuiBox
          id="carbon_section"
          position="relative"
          container
          spacing={2}
          p={{ xs: 2, lg: 4 }}
          sx={({
            palette: {
              staticColors: { white }
            }
          }: theme_types) => ({
            background: white,
            width: "100%",
            height: "100%"
          })}>
          <SuiTypography
            sx={({
              palette: {
                staticColors: { green }
              }
            }: theme_types) => ({
              color: green,
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "uppercase",
              position: "absolute",
              top: "1.2rem",
              left: "1rem",
              letterSpacing: 2
            })}>
            Carbon Emission Reporting
          </SuiTypography>
          <Grid container spacing={3} px={{ xs: 1, sm: 5 }} py={{ xs: 8 }}>
            <Grid item xs={12} lg={6}>
              <Box height={"100%"} display="flex" flexDirection={"column"} justifyContent="center" py={{ xs: 0, lg: 5 }}>
                <SuiTypography
                  variant={"h1"}
                  sx={({
                    palette: {
                      staticColors: { green }
                    }
                  }: theme_types) => ({
                    color: green,
                    fontWeight: 500,
                    fontSize: { xs: "2.5rem", lg: "4rem" },
                    lineHeight: { xs: "2.8rem", lg: "3.8rem" },
                    textTransform: "uppercase"
                  })}>
                  Sustainability
                </SuiTypography>
                <SuiTypography
                  variant={"h1"}
                  sx={{
                    color: dark.main,
                    opacity: 30,
                    fontWeight: 500,
                    fontSize: { xs: "2.5rem", lg: "6rem" },
                    lineHeight: { xs: "2.8rem", lg: "3.8rem" }
                  }}>
                  Environment
                </SuiTypography>

                <Box ml={2} mt={1}>
                  <SuiTypography
                    sx={{
                      color: dark.main,
                      fontSize: { xs: "0.8rem", lg: "0.95rem" },
                      lineHeight: { xs: "1.8rem", lg: "2.3rem" },
                      fontWeight: 600,
                      letterSpacing: { xs: "0.5px", lg: "1.2px" }
                    }}>
                    &#x2022; Accurate Carbon Reporting of Scope 1, 2 and 3 Emissions
                    <br />
                    &#x2022; Net Zero 2050 Goals
                    <br />
                    &#x2022; 9,000 Government Emissions Factors Utilised
                    <br />
                    &#x2022; Data Integration
                    <br />
                    &#x2022; Actual Carbon Emissions Reduction via our <a href="#carboff">Carboff Framework</a>
                  </SuiTypography>
                </Box>

                <Box pt={{ xs: 2, sm: 3 }} textAlign={{ xs: "center", sm: "left" }}>
                  <SuiButton
                    color="dark"
                    component={"a"}
                    onClick={() => handleDashb_navigation(DashboardsEnums.CARBON_TOOL)}
                    target="_blank"
                    size="small"
                    circular
                    sx={({
                      palette: {
                        staticColors: { green, white }
                      }
                    }: theme_types) => ({
                      background: green,
                      color: white
                    })}>
                    GO TO DASHBOARD
                  </SuiButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} position="relative">
              <Box
                display={{ xs: "none", xl: "inline" }}
                component={"img"}
                src={carbonReportingBgImg}
                alt="carbonReportingBgImg"
                width="97%"
                height="96%"
                borderRadius="0.5rem"
              />

              <Stack
                direction="column"
                spacing={5}
                height="100%"
                width={"100%"}
                position={{ xs: "relative", xl: "absolute" }}
                top={0}
                left={0}
                zIndex={1}>
                <SuiBox
                  borderRadius="sm"
                  shadow="sm"
                  overflow={"hidden"}
                  width={{ xs: "100%", sm: "70%", md: "23rem" }}
                  height={{ xs: "90%", sm: "60%", md: "15rem" }}>
                  <img src={pieChartImg} alt="pieChartImg" style={{ width: "100%", height: "100%" }} />
                </SuiBox>

                <SuiBox
                  alignSelf={"flex-end"}
                  overflow={"hidden"}
                  borderRadius="sm"
                  shadow="sm"
                  width={{ xs: "100%", sm: "70%", md: "23rem" }}
                  height={{ xs: "90%", sm: "60%", md: "15rem" }}
                  mr={{ lg: "2rem" }}
                  mb={{ lg: "-2rem" }}>
                  <img src={lineChartImg} alt="lineChartImg" style={{ width: "100%", height: "100%" }} />
                </SuiBox>
              </Stack>
            </Grid>
          </Grid>
        </SuiBox>


        {/* carboff section */}
        <SuiBox
          id="carboff"
          position="relative"
          container
          spacing={2}
          p={{ xs: 2, lg: 4 }}
          sx={({
            palette: {
              staticColors: { white }
            }
          }: theme_types) => ({
            background: white,
            width: "100%",
            height: "100%"
          })}>
          <SuiTypography
            sx={({
              palette: {
                staticColors: { green }
              }
            }: theme_types) => ({
              color: green,
              fontWeight: 600,
              fontSize: "1rem",
              textTransform: "uppercase",
              position: "absolute",
              top: "1rem",
              left: "1rem",
              letterSpacing: 2
            })}>
            Adakas Carboff Framework
          </SuiTypography>
          <Grid container spacing={3} px={{ xs: 1, sm: 5 }} py={{ xs: 8 }}>

            
            <Grid item xs={12} lg={6}>

              
              <Box height={"100%"} display="flex" flexDirection={"column"} justifyContent="center" py={{ xs: 0, lg: 5 }}>
              <Stack
                direction="column"
                spacing={5}
                height="100%"
                width={"100%"}
                position={{ xs: "relative", xl: "relative" }}
                top={0}
                left={0}
                zIndex={1}>

                <SuiBox
                  alignSelf={"flex-end"}
                  overflow={"hidden"}
                  borderRadius="sm"
                  shadow="sm"
                  width={{ xs: "100%", sm: "100%", md: "40rem" }}
                  height={{ xs: "100%", sm: "100%", md: "30rem" }}
                  mr={{ lg: "8rem" }}
                  mb={{ lg: "2rem" }}>
                  <img src={solarpanelImg} alt="solarpanelImg" style={{ width: "100%", height: "100%" }} />
                </SuiBox>
              </Stack>

              
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} position="relative">

            <SuiTypography
                  variant={"h1"}
                  sx={({
                    palette: {
                      staticColors: { green }
                    }
                  }: theme_types) => ({
                    color: green,
                    fontWeight: 500,
                    fontSize: { xs: "2.5rem", lg: "4rem" },
                    lineHeight: { xs: "2.8rem", lg: "3.8rem" },
                    textTransform: "uppercase"
                  })}>
                  Decarbonise
                </SuiTypography>
                <SuiTypography
                  variant={"h1"}
                  sx={{
                    color: dark.main,
                    opacity: 30,
                    fontWeight: 500,
                    fontSize: { xs: "2.5rem", lg: "3.5rem" },
                    lineHeight: { xs: "2.8rem", lg: "3.8rem" }
                  }}>
                  CarbON &#x2192; CarbOFF
                </SuiTypography>

                <Box ml={2} mt={1}>
                  <SuiTypography
                    sx={{
                      color: dark.main,
                      fontSize: { xs: "0.8rem", lg: "0.95rem" },
                      lineHeight: { xs: "1.8rem", lg: "2.3rem" },
                      fontWeight: 600,
                      letterSpacing: { xs: "0.5px", lg: "1.2px" }
                    }}>
                    &#x2022; Coupled with your carbon reporting to actually reduce emissions
                    <br />
                    &#x2022; Off grid solar power at your emission hotspots (e.g. data centres)
                    <br />
                    &#x2022; Fully installed and monitored with over 40% more power output than static panels
                    <br />
                    &#x2022; <a href="#contact_section">Contact us</a> to find out more
                  </SuiTypography>
                </Box>


            </Grid>
          </Grid>
        </SuiBox>

        {/* kyc */}
        <SuiBox
          id="kyc_section"
          sx={{
            backgroundImage: `url(${kycBgImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "relative"
          }}>
          <SuiBox
            container
            spacing={2}
            py={{ xs: 4, lg: 6 }}
            sx={({
              palette: {
                staticColors: { darkGray }
              },
              functions: { rgba }
            }: theme_types) => ({
              background: rgba(darkGray, 0.9),
              width: "100%",
              height: "100%"
            })}>
            <SuiTypography fontSize={{ sm: "1.5rem", lg: "2.2rem" }} align="center" sx={{ color: white.main, fontWeight: 500 }}>
              Know Your Customer (KYC)
            </SuiTypography>
            <Grid container spacing={3} py={{ xs: 2, md: 8 }} px={{ xs: 2 }}>
              <Grid item xs={12} lg={6}>
                <Stack direction="column" spacing={3.5} height="100%" alignItems={"center"} justifyContent="center">
                  <SuiBox
                    shadow="sm"
                    overflow={"hidden"}
                    borderRadius="md"
                    width={{ xs: "100%", sm: "70%", md: "25rem" }}
                    height={{ xs: "90%", sm: "60%", md: "17rem" }}>
                    <img src={adakasLineChartImg} alt="adakasLineChartImg" style={{ width: "100%", height: "100%" }} />
                  </SuiBox>

                  <SuiBox
                    shadow="sm"
                    overflow={"hidden"}
                    borderRadius="md"
                    width={{ xs: "100%", sm: "70%", md: "25rem" }}
                    height={{ xs: "90%", sm: "60%", md: "17rem" }}>
                    <img src={adakasEntityImg} alt="adakasEntityImg" style={{ width: "100%", height: "100%" }} />
                  </SuiBox>
                  <SuiButton
                    size="small"
                    circular
                    setFixedColor="white"
                    onClick={handleDashb_navigation}
                    style={{ marginTop: 40 }}>
                    GO TO DASHBOARD
                  </SuiButton>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={6} py={{ lg: 3 }}>
                <Grid
                  container
                  spacing={3}
                  px={{ xs: 2, lg: 4 }}
                  sx={{ "& > div > div": { "& p,& span": { color: white.main } } }}>
                  <Grid item xs={12} lg={6}>
                    <ServiceCard
                      imgSrc="https://img.icons8.com/ultraviolet/344/module.png"
                      title="Adverse Media Search"
                      descp="Deep AI providing search matches on individuals or companies from multiple news sources"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <ServiceCard
                      imgSrc="https://img.icons8.com/ultraviolet/344/module.png"
                      title="PEPs and Sanctions"
                      descp="Checks on entities against officially recognised and updated lists"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <ServiceCard
                      imgSrc="https://img.icons8.com/ultraviolet/344/module.png"
                      title="Ongoing Monitoring"
                      descp="Automated checks run at a schedule that suits your needs and with full alerting capabilities"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiBox>

        {/* opportunities */}
        <SuiBox id="opportunities_section" display="flex" alignItems="center" flexDirection="column" py={7}>
          <Grid container spacing={2} p={{ xs: 2, lg: 5 }}>
            <Grid item xs={12} md={6}>
              <Box display={"flex"} alignItems="center" height={"100%"} px={{ xs: 2, lg: 5 }}>
                <Box
                  sx={{
                    backgroundImage: `url(${opporImg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    clipPath: `polygon(0 5%, 100% 0, 100% 95%, 0% 100%)`,
                    width: "100%"
                  }}
                  height={{ xs: "30vh", lg: "45vh" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box textAlign="left" px={2}>
                <SuiTypography variant="h6" sx={{ color: info.main }}>
                  OPPORTUNITIES
                </SuiTypography>
                <SuiTypography variant="h5" mt={1} sx={{ color: dark.main }}>
                  Careers and Partnerships
                </SuiTypography>
              </Box>

              <Grid container spacing={3} px={3} py={5}>
                <Grid item xs={12} lg={6}>
                  <ServiceCard imgSrc="https://img.icons8.com/ultraviolet/344/module.png" title="Careers" descp="" />
                  <SuiTypography variant="body3" sx={{ color: text.main }}>
                    We are always on the lookout for talented staff so please <a href="#contact_section">get in touch</a> with us
                    if you are looking for new exciting opportunities.
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ServiceCard imgSrc="https://img.icons8.com/ultraviolet/344/module.png" title="Partnerships" descp="" />
                  <SuiTypography variant="body3" sx={{ color: text.main }}>
                    Through our alumni relations, we have developed a strong partnership with a leading London based Russell Group
                    university. We run presentations aimed at offering aspiring undergraduates an insight into finance and
                    technology. If you would like to find out more about such partnerships, please{" "}
                    <a href="#contact_section">contact us</a>.
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ServiceCard imgSrc="https://img.icons8.com/ultraviolet/344/module.png" title="Testimonials" descp="" />

                  <SuiTypography variant="body3" sx={{ color: text.main, fontWeight: "bold", fontStyle: "italic" }}>
                    "It was so valuable to hear the three founders’ perspectives on this emerging industry, and they struck a good
                    balance between explaining the technical and business aspects of Adakas"
                    <br></br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Isabella, attendee at FinTech Insight Week
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} lg={6}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>

        {/* contacts */}
        <SuiBox
          id="contact_section"
          sx={{
            backgroundImage: `url(${contactImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "relative"
          }}>
          <SuiBox
            display="flex"
            alignItems="center"
            flexDirection="column"
            py={8}
            sx={({
              palette: {
                staticColors: { white }
              },
              functions: { rgba }
            }: theme_types) => ({
              background: rgba(white, 0.75),
              width: "100%",
              height: "100%"
            })}>
            <Box textAlign="center">
              <SuiTypography variant="h3" sx={{ color: dark.main }}>
                Contact Us
              </SuiTypography>
              <SuiTypography variant="body3" sx={{ color: text.main }}>
                We are only one step away
              </SuiTypography>
            </Box>

            <Box width={{ xs: "75%", sm: "48%", md: "38%", lg: "30%" }} mt={2}>
              <ContactForm />
            </Box>
          </SuiBox>
        </SuiBox>
      </StaticLayout>
      {/* modals */}
      <AuthModal open={open} setOpen={setOpen} navigateTo={currentDashboard} />
    </React.Fragment>
  );
};

export default HomePage;
