import React from "react";
import { useForm, Controller } from "react-hook-form";

// @mui material components
import { Box } from "@mui/material";

// custom components
import { DatePicker } from "components";

import { useDispatch } from "react-redux";

// actions
import { getSearchedDataAction } from "store/actions";

import { _format } from "utils/helper/_date";

interface searchDate_Field {
  searchDate: Date | number;
}

const SearchByDate = () => {
  const dispatch = useDispatch();
  const { control, watch } = useForm<searchDate_Field>();

  const fetchSearchData = React.useCallback(
    (date: Date | number) => {    
      process.env.REACT_APP_USE_API_DATA !== "0" && dispatch(getSearchedDataAction(_format(date, "yyyy-MM-dd HH:mm")));
    },
    [dispatch]
  );

  React.useEffect(() => fetchSearchData(watch("searchDate")), [watch, fetchSearchData]);

  return (
    <Box my={1.4}>
      <Controller
        control={control}
        name="searchDate"
        render={({ field: { onChange, value } }) => (
          <DatePicker
            label="Select Date"
            value={value}
            onChange={onChange}
            onAccept={(date: Date | number) => fetchSearchData(date)}
          />
        )}
      />
    </Box>
  );
};

export default SearchByDate;
