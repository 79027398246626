import { Palette } from "assets/theme/base/colorsT";

const tablePagination = (palette: Palette) => {
  const { secondary } = palette;
  return {
    styleOverrides: {
      root: {
        "& .MuiToolbar-root": {
          "& .MuiTablePagination-displayedRows": {
            color: secondary.main,
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: 1.25
          },
          "& .MuiTablePagination-actions": {
            marginLeft: "0.4rem",
            color: secondary.main,
            "& .MuiButtonBase-root": {
              padding: 0,
              fontSize: "1.2rem"
            }
          }
        }
      }
    }
  };
};

export default tablePagination;
