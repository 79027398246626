import { Palette } from "assets/theme/base/colorsT";

const globals = (palette: Palette) => {
  const { info, dark, sliderColors } = palette;
  return {
    html: {
      scrollBehavior: "smooth"
    },
    "*, *::before, *::after": {
      margin: 0,
      padding: 0
    },
    "a, a:link, a:visited": {
      textDecoration: "none !important"
    },
    "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
      color: `${dark.main} !important`,
      transition: "color 150ms ease-in !important"
    },
    "a.link:hover, .link:hover, a.link:focus, .link:focus": {
      color: `${info.main} !important`
    },
    iframe: {
      border: "unset"
    },
    // "::selection": {
    //   background: dark.main,
    //   color: white.main
    // },

    // scroll bar styles for webkit
    "*::-webkit-scrollbar": {
      width: "6px",
      height: "6px"
    },
    "*::-webkit-scrollbar-track": {
      background: "rgba(218, 7, 7, 0)"
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: sliderColors.thumb.borderColor,
      borderRadius: "0.5rem"
    },
    // other than webkit
    "*": {
      scrollbarWidth: "6px",
      scrollbarColor: `${sliderColors.thumb.borderColor} rgba(218, 7, 7, 0)`
    }
  };
};

export default globals;
