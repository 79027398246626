import React from "react";
import { UseAppContext } from "context/App";

// @mui material components
import { ThemeProvider, CssBaseline } from "@mui/material";
import { createTheme } from "@mui/material/styles";

// theme
import makeTheme from "assets/theme";

// routes
import AppRoutes from "./routes";

import ToastContainer from "extraComp/toast";

export default function App() {
  const { mode } = UseAppContext();

  const theme = React.useMemo(() => createTheme(makeTheme(mode)), [mode]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {React.useMemo(
        () => (
          <AppRoutes />
        ),
        []
      )}
      <ToastContainer />
    </ThemeProvider>
  );
}
