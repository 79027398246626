import React from "react";
import { useSelector } from "react-redux";
// import { UseAppContext } from "context/App";

// interface
import { allSlices_T } from "store/slices";
import { matchesData_T } from "store/slices/searchedData/SearchData";

import DraggableComp from "extraComp/draggable";

// table
import CustomizedTable from "extraComp/table";
import { strongTableConfig, weekTableConfig, noMatchesTableConfig } from "utils/tableConfig";
import strongMatchesData from "json/table_data_strong_match.json";
import weekMatchesData from "json/table_data_weak_matches.json";
import noMatchesData from "json/table_data_no_matches.json";

import { v4 as uuidv4 } from "uuid";

const LayoutOrder = () => {
  const searchedData = useSelector((s: allSlices_T) => s.searchedData);
  const { data, load, error } = searchedData as matchesData_T;

  // const { layoutOrder } = UseAppContext();

  const allTables = React.useMemo(() => {
    return process.env.REACT_APP_USE_API_DATA === "0"
      ? [
          {
            id: uuidv4(),
            reactComp: (
              <CustomizedTable
                label="Strong Matches"
                columns={strongTableConfig}
                data={{ rows: strongMatchesData, load: false, error: false }}
                dataCy="strongMatch_table"
              />
            )
          },
          {
            id: uuidv4(),
            reactComp: (
              <CustomizedTable
                label="Weak Matches"
                columns={weekTableConfig}
                data={{ rows: weekMatchesData, load: false, error: false }}
              />
            )
          },
          {
            id: uuidv4(),
            reactComp: (
              <CustomizedTable
                label="No Matches"
                columns={noMatchesTableConfig}
                data={{ rows: noMatchesData, load: false, error: false }}
              />
            )
          }
        ]
      : [
          {
            id: uuidv4(),
            reactComp: (
              <CustomizedTable
                label="Strong Matches"
                columns={strongTableConfig}
                data={{ rows: data.strongData, load: load, error: error }}
              />
            )
          },
          {
            id: uuidv4(),
            reactComp: (
              <CustomizedTable
                label="Weak Matches"
                columns={weekTableConfig}
                data={{ rows: data.weakData, load: load, error: error }}
              />
            )
          },
          {
            id: uuidv4(),
            reactComp: (
              <CustomizedTable
                label="No Matches"
                columns={noMatchesTableConfig}
                data={{ rows: data.noData, load: load, error: error }}
              />
            )
          }
        ];
  }, [data, load, error]);

  // const [tableList, setTableList] = React.useState(defaultTableList);

  // React.useLayoutEffect(() => {
  //   if (!!layoutOrder) {
  //     const newIndexes = layoutOrder.split(",");
  //     setTableList(newIndexes.map((i) => tableList[parseInt(i)]));
  //   }
  // }, [layoutOrder]);
  //     <DraggableComp list={!!layoutOrder ? tableList : defaultTableList} setList={setTableList} />

  return <DraggableComp compList={allTables} />;
};

export default LayoutOrder;
