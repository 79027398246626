import React from "react";

import { TextField, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { makeColors } from "assets/theme/base/colors";

interface datePicker_types {
  label: string;
  value: any;
  onChange: any;

  // other props
  [rest: string]: any;
}

const DaTePicker = ({ label, value, onChange, ...rest }: datePicker_types) => {
  const theme = useTheme();
  const { white, grey, secondary } = makeColors(theme.palette.mode);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={onChange}
        renderInput={(params:any) => (
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                background: white.main,
                color: secondary.main,
                borderColor: grey[300]
              }
            }}
            size="small"
            placeholder={label}
            {...params}
          />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DaTePicker;
