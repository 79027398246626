import React from "react";
// @mui material components
import { useTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Box } from "@mui/material";

const ToastifyElement = () => {
  const mode = useTheme().palette.mode;
  return (
    <Box
      sx={{
        "& .Toastify__toast": {
          fontFamily: "inherit",
          fontSize: "0.9rem",
          fontWeight: 500,
          padding: "5px"
        }
      }}>
      <ToastContainer position="bottom-right" autoClose={3000} theme={mode} />
    </Box>
  );
};

export default ToastifyElement;
