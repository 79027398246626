import React from "react";

// components
import { BasicModal, SuiBox } from "components";
// mui imports
import { DialogContent, Box, Tabs, Tab } from "@mui/material";

// icons
import { HiOutlineUpload, HiOutlineDownload, HiOutlineDocumentAdd, HiOutlineSearch, HiOutlineServer } from "react-icons/hi";

// tabs comp
import ImportData from "./ImportData";
import ExportData from "./ExportData";
import AddNewEntity from "./AddNew";
import EditEnitity from "./EditEntity";
import ManageSearchTerms from "./SearchTerms";

interface WorkflowModal_PT {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface TabPanelProps {
  comp?: React.ReactNode;
  index: number;
  value: number;
}

export interface tab_PT {
  handleClose: () => void;
}

function TabPanel(props: TabPanelProps) {
  const { comp, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`tabPanel_Cont-${index}`}
      pl={2.3}
      width="37.188rem"
      minHeight={"30.875rem"}
      {...other}>
      {value === index && comp}
    </Box>
  );
}

const WorkflowModal = ({ open, setOpen }: WorkflowModal_PT) => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleClose = () => setOpen(false);
  const handleSetTabValue = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

  return (
    <BasicModal modalName="workFlow" open={open} onClose={() => null} maxWidth="lg" data-cy="workflowModal">
      <SuiBox variant="gradient" bgColor="dark">
        <DialogContent sx={{ display: "flex" }}>
          {/* tabs */}
          <Box display={"flex"}>
            <Tabs
              orientation="vertical"
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{
                background: "transparent",
                pb: 15,
                "& button:not(:first-of-type)": {
                  marginTop: 1.5
                }
              }}>
              <Tab label="Import Data" icon={<HiOutlineUpload />} data-cy="wf_modal_importData" />
              <Tab label="Export Data" icon={<HiOutlineDownload />} data-cy="wf_modal_exportData" />
              <Tab label="Add New Entity" icon={<HiOutlineDocumentAdd />} data-cy="wf_modal_addData" />
              <Tab label="Edit Entity" icon={<HiOutlineSearch />} data-cy="wf_modal_editData" />
              <Tab label="Manage Search Terms" icon={<HiOutlineServer />} data-cy="wf_modal_manageSearchItems" />
            </Tabs>

            {/* vertical line */}
            <SuiBox height={"100%"} width={5} ml={2.3} borderRadius="1rem" bgColor={"light"} />
          </Box>

          {/* content */}
          <>
            <TabPanel value={tabValue} index={0} comp={<ImportData handleClose={handleClose} />} />
            <TabPanel value={tabValue} index={1} comp={<ExportData handleClose={handleClose} />} />
            <TabPanel value={tabValue} index={2} comp={<AddNewEntity handleClose={handleClose} />} />
            <TabPanel value={tabValue} index={3} comp={<EditEnitity handleClose={handleClose} />} />
            <TabPanel value={tabValue} index={4} comp={<ManageSearchTerms handleClose={handleClose} />} />
          </>
        </DialogContent>
      </SuiBox>
    </BasicModal>
  );
};

export default WorkflowModal;
