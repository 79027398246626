//  React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

//  React helper functions
import rgba from "assets/theme/functions/rgba";
import pxToRem from "assets/theme/functions/pxToRem";

const { white } = colors;
const { borderRadius } = borders;

const sidenav = {
  styleOverrides: {
    root: {
      width: pxToRem(260),
      whiteSpace: "nowrap",
      border: "none"
    },

    paper: {
      width: pxToRem(260),
      backgroundColor: rgba(white.main, 0.8),
      backdropFilter: `saturate(200%) blur(${pxToRem(30)})`,
      height: `calc(100% - ${pxToRem(24)})`,
      margin: pxToRem(12),
      borderRadius: borderRadius.md,
      border: "none",
      overflowX: "hidden"
    },

    paperAnchorDockedLeft: {
      borderRight: "none"
    }
  }
};

export default sidenav;
