import React from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

// components
import { BasicModal, SuiBox, SuiTypography, SuiInput, SuiButton, DropDown } from "components";
import borders from "assets/theme/base/borders";

// mui imports
import { DialogContent, Box, Checkbox, FormControl, useTheme } from "@mui/material";

// dummy data
import urlsData from "json/urls.json";

//  dropdown options
import { entityStatusOptions, workStatusOptions } from "utils/constants";

// interfaces
import { allSlices_T } from "store/slices";
import { getRowData_T } from "store/slices/rightPanels/getRowData";

interface ActionModal_PT {
  open: boolean;
  setOpen: (open: boolean) => void;
}

type dropDown_T = {
  value: string;
  label: string;
};

interface actionModal_Form {
  workStatus: dropDown_T[];
  entityStatus: dropDown_T[];
  activeInSearches: boolean;
  comment: string;
}

const url_head = ["URL", "Ignored"];
const { borderRadius, borderWidth } = borders;

const ActionModal = ({ open, setOpen }: ActionModal_PT) => {
  const theme = useTheme();
  const borderColor = theme.palette.grey[300];
  const { relvance }: getRowData_T = useSelector((s: allSlices_T) => s.rowBaseData);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<actionModal_Form>();

  const handleAction = (data: actionModal_Form) => {
    console.log(urlsData, data);
  };

  // set default values on modal open
  React.useEffect(() => {
    setValue("entityStatus", [{ value: relvance.status.entity_name, label: relvance.status.entity_name }], {
      shouldValidate: true
    });
    setValue("workStatus", [{ value: relvance.status.value, label: relvance.status.value }], {
      shouldValidate: true
    });
  }, [open, relvance.status.entity_name, relvance.status.value, setValue]);

  const handleClose = () => setOpen(false);

  return (
    <BasicModal modalName="action" open={open} onClose={() => null} maxWidth="lg" data-cy="actionModal">
      <SuiBox variant="gradient" bgColor="dark">
        <DialogContent>
          <Box>
            <SuiTypography variant="h5" color="white" fontWeight="bold">
              {relvance.entity_name}
              <SuiTypography component="span" variant="button" ml={1} color={"light"}>
                {`( ${relvance.score.value} )`}
              </SuiTypography>
            </SuiTypography>
          </Box>
          {/* urls */}
          <Box mt={1.5}>
            <SuiTypography variant="body2" color="light" fontWeight="bold" ml={0.5}>
              URL's
            </SuiTypography>
            <Box mt={1} border={`${borderWidth[1]} solid ${borderColor}`} borderRadius={borderRadius.md}>
              <Box display="flex" justifyContent={"space-between"} alignItems="center">
                {url_head.map((item, i) => (
                  <SuiTypography key={i} variant="caption" color="light" fontWeight="bold" p={1}>
                    {item}
                  </SuiTypography>
                ))}
              </Box>

              <Box display="flex" flexDirection={"column"} justifyContent={"center"} alignItems="space-between">
                {urlsData.map((item, i) => (
                  <Box key={i} display="flex" justifyContent={"space-between"} alignItems="center">
                    <SuiTypography variant="caption" color="light" p={1}>
                      {item.url}
                    </SuiTypography>
                    <Box px={2}>
                      <Checkbox />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          {/* form content */}
          <Box component="form" role="form" onSubmit={handleSubmit(handleAction)}>
            <Box my={1.8} display={"flex"} alignItems="center">
              <SuiTypography variant="nav_head" color="light">
                Work Status
              </SuiTypography>
              <FormControl sx={{ ml: 1.3, width: "12rem" }} size="small">
                <Controller
                  control={control}
                  name="workStatus"
                  render={({ field: { onChange, value } }) => (
                    <DropDown
                      optionsList={workStatusOptions}
                      placeholder="Select Status"
                      isSearchable={false}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FormControl>
            </Box>

            <Box mt={1} display={"flex"} alignItems="center">
              <SuiTypography variant="nav_head" color="light">
                Entity Status
              </SuiTypography>
              <FormControl sx={{ ml: 0.8, width: "12rem" }} size="small">
                <Controller
                  control={control}
                  name="entityStatus"
                  render={({ field: { onChange, value } }) => (
                    <DropDown
                      optionsList={entityStatusOptions}
                      placeholder="Select Status"
                      isSearchable={false}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FormControl>
            </Box>

            <Box mt={1} display={"flex"} alignItems="center">
              <SuiTypography variant="nav_head" color="light">
                Active in searches
              </SuiTypography>
              <Box ml={1}>
                <Checkbox {...register("activeInSearches")} />
              </Box>
            </Box>

            <Box>
              <SuiTypography variant="nav_head" color="light">
                Comment
              </SuiTypography>
              <Box mt={0.5}>
                <SuiInput
                  multiline
                  rows={4}
                  placeholder="Comment here"
                  {...register("comment", {
                    required: "Field is required"
                  })}
                  error={errors.comment?.message}
                />
              </Box>
            </Box>

            {/* Buttons */}
            <Box
              mt={2}
              display={"flex"}
              justifyContent="flex-end"
              sx={{
                "& button:not(:first-of-type)": {
                  ml: 1.5
                }
              }}>
              <SuiButton color="light" size="small" circular onClick={handleClose} data-cy="actionModal_close">
                Cancel
              </SuiButton>
              <SuiButton color="light" size="small" circular type="submit">
                Save
              </SuiButton>
            </Box>
          </Box>
        </DialogContent>
      </SuiBox>
    </BasicModal>
  );
};

export default ActionModal;
