// users Slice
import UsersSlice from "./users/userSlice";

// searched Data
import SearchedDataSlice from './searchedData/SearchData'

// right panels
import rowBaseData from "./rightPanels/getRowData";

export interface allSlices_T {
  users: any;
  searchedData: any
  rowBaseData: any;
}

const rootSlice: allSlices_T = {
  users: UsersSlice,
  searchedData:SearchedDataSlice,
  rowBaseData: rowBaseData
};

export default rootSlice;
