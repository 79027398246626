// layout
import NoNavBarLayout from "layouts/static/NoNavBarLayout";

import React from "react";


// components
import { SuiBox, SuiTypography } from "components";
import { Grid, Box } from "@mui/material";
import ServiceCard from "layouts/static/components/ServiceCard";
import ContactForm from "layouts/static/components/ContactForm";

// modals
import AuthModal from "extraComp/modals/auth";

import { theme_types } from "assets/theme";

// static colors
import colors from "assets/theme/base/colors";
import { DashboardsEnums } from "enums/dashboards.enum";

const ConsultingPage = () => {
  const { white, dark, info, text } = colors;
  const minWidth = 450;

  // use states
  const [open, setOpen] = React.useState<boolean>(false);
  const [width, setWidth] = React.useState<Number>(window.innerWidth);

  React.useEffect(() => {
    const getWindowWidth = () => setWidth(window.innerWidth > minWidth ? window.innerWidth : minWidth);
    // add listner
    window.addEventListener("resize", getWindowWidth);
    // remove listner
    return () => window.removeEventListener("resize", getWindowWidth);
  }, []);

  // images
  const bannerImg = `https://images.pexels.com/photos/157040/pexels-photo-157040.jpeg?auto=compress&w=${width}`,
    aboutimg = `https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg?auto=compress&w=${width}`

  return (
    <NoNavBarLayout>
      {/* banner */}
      <SuiBox
        id="home_section"
        sx={{
          backgroundImage: `url(${bannerImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          clipPath: `polygon(100% 0, 100% ${90}%, 0 97%, 0 0)`,
          height: "80vh",
          position: "relative"
        }}>
        <SuiBox
          sx={({
            palette: {
              staticColors: { darkBlue }
            },
            functions: { rgba }
          }: theme_types) => ({
            background: rgba(darkBlue, 0.8),
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          })}>
          <Box mr={{ sm: 8 }} width={{ xs: "100%", md: "38%" }}>
            <SuiTypography variant={"h2"} p={1} textAlign={{ xs: "center", sm: "right" }} sx={{ color: white.main }}>
              Adakas Consulting
            </SuiTypography>
          </Box>
        </SuiBox>
      </SuiBox>

      {/* opportunities */}
      <SuiBox id="opportunities_section" display="flex" alignItems="center" flexDirection="column" py={7}>
        <Grid container spacing={2} p={{ xs: 2, lg: 5 }}>
          <Grid item xs={12} md={6}>
            <Box textAlign="left" px={2}>
              <SuiTypography variant="h6" sx={{ color: info.main }}>
                FEATURES
              </SuiTypography>
              <SuiTypography variant="h5" mt={1} sx={{ color: dark.main }}>
                Our featured services that we provide
              </SuiTypography>
            </Box>

            <Grid container spacing={3} px={3} py={5}>
              <Grid item xs={12} lg={6}>
                <ServiceCard
                  imgSrc="https://img.icons8.com/ultraviolet/344/module.png"
                  title="Bespoke Solutions"
                  descp="If you are looking to develop a technical solution for your business, contact us to find out how we can be of assistance. Our team of experts will work with you to identify your system requirements to determine the best solutions to help solve your specific requirements."
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ServiceCard
                  imgSrc="https://img.icons8.com/ultraviolet/344/module.png"
                  title="Products"
                  descp="At Adakas we are building a product line of software solutions designed to solve specific problems common in the industry. If you would like access to this off-the-shelf library, please get in touch to learn more."
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display={"flex"} alignItems="center" height={"100%"} px={{ xs: 2, lg: 5 }}>
              <Box
                sx={{
                  backgroundImage: `url(${"https://images.pexels.com/photos/574071/pexels-photo-574071.jpeg?cs=srgb&dl=pexels-lukas-574071.jpg&fm=jpg"})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  clipPath: `polygon(0 5%, 100% 0, 100% 95%, 0% 100%)`,
                  width: "100%"
                }}
                height={{ xs: "30vh", lg: "45vh" }}
              />
            </Box>
          </Grid>
        </Grid>
      </SuiBox>

      {/* about */}
      <SuiBox
        id="about_section"
        sx={{
          backgroundImage: `url(${aboutimg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "70vh",
          position: "relative"
        }}>
        <SuiBox
          sx={({
            palette: {
              staticColors: { darkGray }
            },
            functions: { rgba }
          }: theme_types) => ({
            background: rgba(darkGray, 0.85),
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          })}>
          <Box mr={{ xs: 1.5, sm: 8 }} width={{ xs: "85%", sm: "50%", md: "38%" }}>
            <Box my={1.5}>
              <SuiTypography variant={"h3"} sx={{ color: white.main }}>
                Who We Are
              </SuiTypography>
            </Box>
            <Box my={1.5}>
              <SuiTypography variant={"body3"} textAlign="right" sx={{ color: white.main }}>
                We have over 40 years’ combined experience building software solutions for various companies, including tier-1 investment banks and global media firms. Our team of experts bring their wealth of experience to design and develop custom software solutions to meet your business needs.
              </SuiTypography>
            </Box>
          </Box>
        </SuiBox>
      </SuiBox>

      {/* contacts */}
      <SuiBox
        id="contact_section"
        sx={{
          //backgroundImage: `url(${contactImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative"
        }}>
        <SuiBox
          display="flex"
          alignItems="center"
          flexDirection="column"
          py={8}
          sx={({
            palette: {
              staticColors: { white }
            },
            functions: { rgba }
          }: theme_types) => ({
            background: rgba(white, 0.75),
            width: "100%",
            height: "100%"
          })}>
          <Box textAlign="center">
            <SuiTypography variant="h3" sx={{ color: dark.main }}>
              Contact Us
            </SuiTypography>
            <SuiTypography variant="body3" sx={{ color: text.main }}>
              We are only one step away
            </SuiTypography>
          </Box>

          <Box width={{ xs: "75%", sm: "48%", md: "38%", lg: "30%" }} mt={2}>
            <ContactForm />
          </Box>
        </SuiBox>
      </SuiBox>

      {/* modals */}
      <AuthModal open={open} setOpen={setOpen} navigateTo={DashboardsEnums.ADAKAS_DASHBOARD} />
    </NoNavBarLayout>
  );
};

export default ConsultingPage;
