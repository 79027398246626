export const makeLineChartData = (labels: string[], data: number[]) => {
  return {
    labels,
    datasets: [
      {
        label: "Risk score",
        color: "info",
        data
      }
    ]
  };
};

export const makeHoriz_barChart = (labels: string[], data: number[]) => {
  return {
    labels,
    datasets: [
      {
        label: "Relevance Score",
        color: "info",
        data
      }
    ]
  };
};

export const makePieChartData = (labels: string[], data: number[]) => {
  return {
    labels,
    datasets: {
      label: "Relevance Score Breakdown",
      backgroundColors: ["info", "primary", "dark"],
      data
    }
  };
};
