import React, { useRef, useEffect, useState, useMemo } from "react";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// components
import { SuiBox, SuiTypography } from "components";

// helper functions
import gradientChartLine from "assets/theme/functions/gradientChartLine";

// GradientLineChart configurations
import configs from "extraComp/charts/lineChart/configs";

// base styles
import colors from "assets/theme/base/colors";

interface lineChart_types {
  title?: string;
  description?: string | React.ReactNode;
  height?: string | number;
  chart?: {
    labels: string[];
    datasets: any[];
  };
}

type chart_types = {
  data: any;
  options: any;
};

type LineChar_dataSet_type = {
  label?: string;
  color: "dark" | "info";
  data?: number[];
};

function GradientLineChart({ title, description, height = "19.125rem", chart }: lineChart_types) {
  const chartRef = useRef<any>(null);
  const [chartData, setChartData] = useState<chart_types>({
    data: null,
    options: null
  });
  const { data, options } = chartData || {};

  useEffect(() => {
    const chartDatasets = chart!.datasets
      ? chart!.datasets.map((dataset: LineChar_dataSet_type) => ({
          ...dataset,
          tension: 0.4,
          pointRadius: 0,
          borderWidth: 3,
          borderColor: colors[dataset.color] ? colors[dataset.color || "dark"].main : colors.dark.main,
          fill: true,
          maxBarThickness: 6,
          backgroundColor: gradientChartLine(
            chartRef.current.children[0],
            colors[dataset.color] ? colors[dataset.color || "dark"].main : colors.dark.main
          )
        }))
      : [];

    setChartData(configs(chart!.labels || [], chartDatasets));
  }, [chart]);

  const renderChart = (
    <SuiBox p={2}>
      {title || description ? (
        <SuiBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <SuiBox mb={1}>
              <SuiTypography variant="h6">{title}</SuiTypography>
            </SuiBox>
          )}
          <SuiBox mb={2}>
            <SuiTypography component="div" variant="button" fontWeight="regular" color="text">
              {description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      ) : null}
      {useMemo(
        () => (
          <SuiBox ref={chartRef} sx={{ height }}>
            <Line data={data} options={options} />
          </SuiBox>
        ),
        [data, options, height]
      )}
    </SuiBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

export default GradientLineChart;
