import { Palette } from "assets/theme/base/colorsT";

//  React base styles
import borders, { makeBorderColor } from "assets/theme/base/borders";
const { borderRadius, borderWidth } = borders;

const tableContainer = (palette: Palette) => {
  const { white } = palette;
  const borderColor = makeBorderColor(palette);
  return {
    styleOverrides: {
      root: {
        backgroundColor: white.main,
        borderRadius: borderRadius.md,
        border: `${borderWidth[1]} solid ${borderColor}`,
        whiteSpace: "nowrap"
      }
    }
  };
};

export default tableContainer;
