import React from "react";

// components
import { SuiBox } from "components";

// nav Components
import NavBar from "layouts/main/components/navbar";

interface PT {
  children?: React.ReactNode;
}

function MainLayout({ children }: PT) {
  return (
    <SuiBox
      sx={() => ({
        p: 1.5,
        position: "relative"
      })}>
      <NavBar />
      {children}
    </SuiBox>
  );
}

export default MainLayout;
