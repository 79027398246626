import React from 'react'

// @mui imports
import { Box, TableRow} from "@mui/material";

// types
import { MsgWrapper_T } from "./types";

const MsgWrapper =(props: MsgWrapper_T) => (
    <TableRow>
      <Box component="td" colSpan={props.colSpan} textAlign="center">
        {props.children}
      </Box>
    </TableRow>
  );

export default MsgWrapper