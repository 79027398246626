import React from "react";

// @mui imports
import { Stack, CircularProgress } from "@mui/material";

// components
import { SuiTypography } from "components";

const LoadingComp = () => {
  return (
    <Stack justifyContent="center" alignItems="center" spacing={0.5} mt={1}>
      <CircularProgress size={19} color="secondary" />
      <SuiTypography variant="caption" color={"secondary"} fontWeight="medium">
        Loading
      </SuiTypography>
    </Stack>
  );
};

export default LoadingComp;
