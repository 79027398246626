import React from "react";
import { useSelector } from "react-redux";

// layouts
import MainLayout from "layouts/main/MainLayout";

// @mui material components
import { Grid, Box } from "@mui/material";

// icons
import { MdInfoOutline } from "react-icons/md";

// custom components
import { SuiBox, SuiTypography } from "components";

// charts
import LineChart from "extraComp/charts/lineChart";
import HoriBarChart from "extraComp/charts/horizontalBarChart";
import PieChart from "extraComp/charts/pieChart";
import { makeLineChartData, makeHoriz_barChart, makePieChartData } from "utils/chartsConfig";

// cards
import StatsCard from "extraComp/StatsCard";
import KPICards from "json/kpi_cards.json";

// dashbaoad Comp
import SearchByDate from "layouts/main/components/dashboardComp/SearchByDate";
import LayoutOrder from "layouts/main/components/dashboardComp/LayoutOrder";
import UserInfo from "layouts/main/components/dashboardComp/UserInfo";
import ModalsHandler from "layouts/main/components/dashboardComp/ModalsHandler";

// interfaces
import { allSlices_T } from "store/slices";
import { getRowData_T } from "store/slices/rightPanels/getRowData";

const DashboardPage = () => {
  const { relvance, riskScore, barChart, piChart }: getRowData_T = useSelector((s: allSlices_T) => s.rowBaseData);

  return (
    <MainLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5}>
          <SuiBox bgColor="white" borderRadius="md" shadow="sm" p={1.8}>
            <SearchByDate />
            <LayoutOrder />
          </SuiBox>
        </Grid>

        <Grid item xs={12} lg={7}>
          <SuiBox bgColor="white" borderRadius="md" shadow="sm" sx={{ overflow: "hidden" }}>
            <Box>
              {/* USER DETAIL */}
              <UserInfo relvance={relvance} />
              {/* line chart */}
              <LineChart height="18rem" chart={makeLineChartData(riskScore.labels, riskScore.data)} />
            </Box>

            {/* spacer */}
            <Box py={1.4}></Box>

            {/* bar & pie chart */}
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box textAlign="center">
                  <SuiTypography variant="body3" textAlign="center" color="text">
                    Data Sources
                  </SuiTypography>
                </Box>
                <HoriBarChart height="19.1rem" chart={makeHoriz_barChart(barChart.labels, barChart.data)} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box textAlign="center">
                  <SuiTypography variant="body3" color="text">
                    Relevance Score Breakdown
                  </SuiTypography>
                </Box>
                <Box my={5}>
                  <PieChart height="12rem" chart={makePieChartData(piChart.labels, piChart.data)} />
                </Box>
              </Grid>
            </Grid>
          </SuiBox>
        </Grid>
      </Grid>

      {/* card section */}
      <SuiBox bgColor="white" borderRadius="md" shadow="sm" sx={{ position: "relative" }} mt={3.5} px={1.8}>
        <Grid container spacing={2} pt={0.5} pb={2}>
          {KPICards.map((item: any, i) => (
            <Grid key={i} item xs={12} sm={6} md={4} xl={2}>
              <StatsCard
                title={{ text: item.display_name, fontWeight: "regular" }}
                count={item.number}
                percentage={{
                  color: Number(item.change) > 1 ? "success" : "error",
                  text: `${item.change} ${item.change_unit}`
                }}
                icon={{ color: "info", component: <MdInfoOutline /> }}
                tooltipTxt={item.infoTxt}
              />
            </Grid>
          ))}
        </Grid>
      </SuiBox>
      {/* modals */}
      <ModalsHandler />
    </MainLayout>
  );
};

export default DashboardPage;
