//  React base styles
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

//  React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { size, fontWeightRegular } = typography;
const { borderRadius, borderWidth } = borders;
const { dark, light, grey } = colors;

const tab = {
  styleOverrides: {
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      flex: "1 1 auto",
      maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "unset !important",
      fontSize: size.sm,
      fontWeight: fontWeightRegular,
      textTransform: "none",
      lineHeight: "inherit",
      padding: `${pxToRem(10)} ${pxToRem(16)} ${pxToRem(10)} ${pxToRem(10)}`,
      border: `${borderWidth[1]} solid ${grey[500]}`,
      borderRadius: borderRadius.md,
      color: `${light.main}`,
      opacity: "1 !important",

      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: pxToRem(4)
      },

      "& svg": {
        marginBottom: "0 !important",
        marginRight: pxToRem(4)
      },

      "&.Mui-selected": {
        color: `${dark.main}`,
        borderColor: "transparent",
        transition: "all 400ms ease"
      }
    },

    labelIcon: {
      // paddingTop: pxToRem(4),
    }
  }
};

export default tab;
