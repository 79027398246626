export const lineChartData = {
  labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  data: [50, 40, 300, 220, 500, 250, 400, 230, 500]
};

export const horiz_barChartData = {
  labels: ["BBC News", "Google", "Sky", "Reuters"],
  data: [200, 240, 300, 500]
};

export const pieChartData = {
  labels: ["Strong Match", "Weak Match", "No Matches"],
  data: [200, 240, 300]
};

export const workStatusOptions = [
  { value: "new", label: "New" },
  { value: "pending", label: "Pending" },
  { value: "in progress", label: "In progress" },
  { value: "completed", label: "Completed" },
  { value: "cancelled", label: "Cancelled" }
];

export const entityStatusOptions = [
  { value: "strong match", label: "Strong Match" },
  { value: "weak match", label: "weak Match" },
  { value: "no match", label: "No Match" }
];

export const searchTermsOptions = [
  { value: "lorem", label: "Lorem" },
  { value: "ispum", label: "Ispum" },
  { value: "lorem ispum", label: "Lorem Ispum" }
];

export const changeLayoutOptions = [
  { value: "0,1,2", label: "S,W,N" },
  { value: "0,2,1", label: "S,N,W" },
  { value: "1,0,2", label: "W,S,N" },
  { value: "1,2,0", label: "W,N,S" },
  { value: "2,0,1", label: "N,S,W" },
  { value: "2,1,0", label: "N,W,S" }
];

export const csvExportHeader = [
  { key: "entity_name", label: "Name" },
  { key: "risk_score", label: "Relevance Score" },
  { key: "is_new", label: "New" },
  { key: "status", label: "Match Status" },
  { key: "work_status", label: "Work Status" },
  { key: "link", label: "Link" }
];

export const top100Films = [
  { title: "bribe", year: 1984 },
  { title: "drug", year: 2010 },
  { title: "felony", year: 2017 },
  { title: "misconduct", year: 1987 },
  { title: "fraud", year: 2016 },
  { title: "theft", year: 1973 },
  { title: "money laundering", year: 1995 },
  { title: "sanction", year: 1948 },
  { title: "terrorism financing", year: 1921 },
  { title: "crime", year: 2000 },
  { title: "corruption", year: 2009 },
  { title: "human trafficking", year: 2009 },
  { title: "scam", year: 2009 },
];
