import React from "react";
// import { useForm, Controller } from "react-hook-form";

// components
import { SuiTypography, SuiButton } from "components";

// mui imports
import { Box, Autocomplete, TextField, Chip } from "@mui/material";
import { toastSuccess } from "utils/toast";

import { top100Films } from "utils/constants";

// types
import { tab_PT } from ".";

// type dropDown_T = {
//   value: string;
//   label: string;
// };

// interface seacrhTerms_T {
//   searchTerms: any[];
// }

const SearchTerms = ({ handleClose }: tab_PT) => {
  // const { control, watch } = useForm<seacrhTerms_T>();
  // const [searchTerms, setSearchTerms] = React.useState([""]);

  const handleSave = () => {
    toastSuccess("Save Successfully");
    handleClose();
  };

  return (
    <Box mt={2.5}>
      <SuiTypography variant="body2" color="light" fontWeight="bold" ml={0.5}>
        Manage Search Terms
      </SuiTypography>
      {/* form content */}
      <Box mt={1}>
        <SuiTypography variant="nav_head" color="light" sx={{ ml: 0.5 }}>
          Search Terms
        </SuiTypography>
        <Box mt={1}>
          <Autocomplete
            multiple
            limitTags={16}
            options={top100Films.map((option) => option.title)}
            freeSolo
            defaultValue={top100Films.map((option) => option.title)}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter search Tags"
                sx={{ "& div.MuiInputBase-root": { minHeight: "6rem", alignItems: "flex-start" } }}
              />
            )}
          />
        </Box>

        {/* buttons */}
        <Box
          mt={2.5}
          display={"flex"}
          justifyContent="flex-end"
          sx={{
            "& button:not(:first-of-type)": {
              ml: 1.5
            }
          }}>
          <SuiButton color="light" size="small" circular onClick={handleClose}>
            Cancel
          </SuiButton>
          <SuiButton color="light" size="small" circular onClick={handleSave} data-cy="save_SearchTerms">
            Save
          </SuiButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchTerms;
