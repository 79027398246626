import React from "react";

import { SuiBox, SuiTypography } from "components";
import { Box } from "@mui/material";

// static colors
import colors from "assets/theme/base/colors";

interface ServiceCard_types {
  imgSrc: string;
  title: string;
  descp: string | string[];
}

const ServiceCard = ({ imgSrc, title, descp }: ServiceCard_types) => {
  const { text } = colors;
  return (
    <SuiBox>
      <img src={imgSrc} alt="service_img" style={{ width: "3.5rem" }} />
      <SuiTypography sx={{ color: text.main }}>{title}</SuiTypography>
      {Array.isArray(descp) ? (
        <Box
          component={"ul"}
          sx={{ ml: "1.1rem", mt: "0.58rem", listStyle: "square", "& li:not(:first-of-type)": { mt: "0.8rem" } }}>
          {descp.map((item, i) => (
            <SuiTypography key={i} component="li" variant="body3" sx={{ color: text.main }}>
              {item}
            </SuiTypography>
          ))}
        </Box>
      ) : (
        <SuiTypography variant="body3" sx={{ color: text.main }}>
          {descp}
        </SuiTypography>
      )}
    </SuiBox>
  );
};

export default ServiceCard;
