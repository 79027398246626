import React from "react";

// custom hooks
import useLocalStorage from "hooks/useLocalStorage";

import { PaletteMode } from "@mui/material";

interface AppProvider_PT {
  children: React.ReactNode;
}

interface AppContext_T {
  // layout order
  layoutOrder: string;
  setLayoutOrder: (ordeer: string) => void;
  // theme switch
  mode: PaletteMode;
  switchMode: { toggleColorMode: () => void };
  // modals
  fullscreenComp: React.ReactNode;
  setFullscreenComp: (comp: React.ReactNode) => void;
  actionModal: boolean;
  setActionModal: (open: boolean) => void;
  worflowModal: boolean;
  setWorflowModal: (open: boolean) => void;
  changeLayoutModal: boolean;
  setChangeLayoutModal: (open: boolean) => void;
  userPrefrenceModal: boolean;
  setUserPrefrenceModal: (open: boolean) => void;
}

const AppContext = React.createContext<AppContext_T | null>(null);
AppContext.displayName = "AppContext";

export const AppProvider = ({ children }: AppProvider_PT) => {
  // useState
  const [fullscreenComp, setFullscreenComp] = React.useState<React.ReactNode>(null);
  const [actionModal, setActionModal] = React.useState<boolean>(false);
  const [worflowModal, setWorflowModal] = React.useState<boolean>(false);
  const [changeLayoutModal, setChangeLayoutModal] = React.useState<boolean>(false);
  const [userPrefrenceModal, setUserPrefrenceModal] = React.useState<boolean>(false);

  const [layoutOrder, setLayoutOrder] = useLocalStorage<string>("layoutOrder", "");
  const [mode, setMode] = useLocalStorage<PaletteMode>("mode", "light");

  const switchMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => (prevMode === "light" ? "dark" : "light"));
      }
    }),
    [setMode]
  );

  // context Value
  const initialValue: AppContext_T = {
    mode,
    switchMode,
    layoutOrder,
    setLayoutOrder,
    fullscreenComp,
    setFullscreenComp,
    actionModal,
    setActionModal,
    worflowModal,
    setWorflowModal,
    changeLayoutModal,
    setChangeLayoutModal,
    userPrefrenceModal,
    setUserPrefrenceModal
  };
  return <AppContext.Provider value={initialValue}>{children}</AppContext.Provider>;
};

export const UseAppContext = () => {
  const context = React.useContext(AppContext);
  if (!context) throw new Error("AppContext should be used inside the Provider");
  return context;
};
