import ReactSelect, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";
// base styles
import colors from "assets/theme/base/colors";

const animatedComponents = makeAnimated();

type option_T = {
  value: string;
  label: string;
};

interface dropDown_PT {
  optionsList: option_T[];
  hasError?: boolean;

  // other props
  [rest: string]: any;
}

const DropDown = ({ optionsList, hasError = false, ...rest }: dropDown_PT) => {
  const { error } = colors;
  const customStyles: StylesConfig = {
    container: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.7 : 1
    }),
    control: (provided) => ({
      ...provided,
      minHeight: "1.5rem",
      background: "transparent",
      border: "1.5px solid #fff",
      borderRadius: "0.3rem",
      borderColor: hasError ? `${error.main} !important` : "initial",
      fontSize: "0.9rem"
    }),

    singleValue: (provided) => ({
      ...provided,
      padding: "0rem 0.5rem",
      fontSize: "0.87rem",
      lineHeight: 1,
      color: "#fff"
    }),

    input: (provided) => ({
      ...provided,
      padding: "0rem 0.5rem",
      fontSize: "0.87rem",
      lineHeight: 1,
      color: "white"
    }),

    placeholder: (provided) => ({
      ...provided,
      padding: "0rem 0.5rem",
      fontSize: "0.87rem",
      lineHeight: 1,
      color: "white"
    }),

    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "0.87rem"
    }),

    loadingMessage: (provided) => ({
      ...provided,
      fontSize: "0.87rem"
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "0.87rem",
      color: state.isSelected ? "#fff" : "#344767",
      background: state.isSelected ? "#8392ab" : state.isFocused ? "#dee2e6" : "transparent",

      "&:active": {
        background: "#f4f4f4"
      }
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#fff",
      "&:hover": {
        color: "#fff"
      }
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "#fff",
      "&:hover": {
        color: "#fff"
      }
    })
  };
  return <ReactSelect components={animatedComponents} styles={customStyles} options={optionsList} {...rest} />;
};

export default DropDown;
