import React from "react";

// components
import { SuiTypography, SuiButton } from "components";

// mui imports
import { Box } from "@mui/material";

import EntityForm from "./EntityForm";
import { toastSuccess } from "utils/toast";

// types
import { tab_PT } from ".";

const AddNewEntity = ({ handleClose }: tab_PT) => {
  const handleAdd = () => {
    toastSuccess("Entity Added Successfully");
    handleClose();
  };

  return (
    <Box mt={2.5}>
      <SuiTypography variant="body2" color="light" fontWeight="bold" ml={0.5}>
        Add New Entity
      </SuiTypography>

      {/* form content */}
      <Box mt={2.5}>
        <EntityForm />
        {/* buttons */}
        <Box
          mt={2.5}
          display={"flex"}
          justifyContent="flex-end"
          sx={{
            "& button:not(:first-of-type)": {
              ml: 1.5
            }
          }}>
          <SuiButton color="light" size="small" circular onClick={handleClose}>
            Cancel
          </SuiButton>
          <SuiButton color="light" size="small" circular onClick={handleAdd}>
            Add
          </SuiButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AddNewEntity;
