import React from "react";

import { Link } from "react-router-dom";

// @mui material components
import { Box } from "@mui/material";

// custom compnents
import { SuiBox, SuiTypography } from "components";

// icons
import { HiChevronDown } from "react-icons/hi";

interface navLink_props {
  icon: React.ReactNode;
  name: string;
  navType?: "link" | "button" | "dropDown";
  onNavClick?: {
    triggerAction?: (open: boolean) => void;
    route?: string;
  };
  dataCy?: string;
}

function NavbarLink({ icon, name, navType = "link", onNavClick, dataCy }: navLink_props) {
  return (
    <SuiBox
      component={navType === "link" ? Link : Box}
      to={navType === "link" ? onNavClick!.route : null}
      onClick={navType === "button" ? onNavClick!.triggerAction : () => null}
      mx={1}
      p={1}
      display="flex"
      alignItems="center"
      sx={{ cursor: "pointer", userSelect: "none" }}
      data-cy={dataCy}>
      <Box
        sx={{
          color: ({ palette: { secondary } }: any) => secondary.main,
          verticalAlign: "middle",
          fontSize: "1.1rem",
          display: "flex",
          alignItems: "center"
        }}>
        {icon}
      </Box>
      <SuiTypography
        variant="nav_head"
        fontWeight="regular"
        color={"dark"}
        textTransform="capitalize"
        sx={{ width: "100%", lineHeight: 0 }}>
        &nbsp;{name}
      </SuiTypography>
      {navType === "dropDown" && (
        <Box
          sx={{
            color: ({ palette: { secondary } }: any) => secondary.main,
            fontSize: "0.95rem",
            display: "flex",
            mb: "-3px"
          }}>
          <HiChevronDown />
        </Box>
      )}
    </SuiBox>
  );
}

export default NavbarLink;
