import React from "react";

// components
import { SuiTypography, SuiBox, OTPInput, SuiButton } from "components";
import ErrorMsg from "./ErrorMsg";

// mui imports
import { DialogTitle, DialogContent, Box } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";

// static colors
import colors from "assets/theme/base/colors";

// enums
import { DashboardsEnums } from "enums/dashboards.enum";

interface OTPField_PT {
  otp: number | undefined;
  navigateTo: DashboardsEnums;
}

interface OTP_FormInp {
  otpField: string;
}

const ROUTES = {
  CARBON_TOOL: process.env.REACT_APP_CARBON_REPORTING_TOOL || '/dashboard',
  ADAKAS_DASHBOARD: "/dashboard",
};

const OTPField = ({ otp, navigateTo }: OTPField_PT) => {
  const { dark } = colors;

  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    setError,
    handleSubmit
  } = useForm<OTP_FormInp>({
    defaultValues: {
      otpField: "1234"
    }
  });

  const verifyOTP = (data: OTP_FormInp) => {
    // verify OTP
    if (otp === Number(data.otpField)) {
      if (navigateTo === DashboardsEnums.CARBON_TOOL) {
        window.location.href = ROUTES.CARBON_TOOL;
      } else {
        navigate(ROUTES.ADAKAS_DASHBOARD);
      }
    } else {
      setError("otpField", {
        type: "mannual",
        message: "Invalid OTP"
      });
    }
  };

  return (
    <Box>
      <DialogTitle>
        <SuiTypography variant="h4" component="span" sx={{ color: dark.main }}>
          Enter OTP
        </SuiTypography>
        <br />
        <SuiTypography variant="body3" mt={1} sx={{ color: dark.main }}>
          OTP will expire after 30 seconds
        </SuiTypography>
      </DialogTitle>
      <DialogContent>
        <SuiBox component="form" role="form" onSubmit={handleSubmit(verifyOTP)}>
          <SuiBox mb={1} textAlign="center">
            <Controller
              control={control}
              rules={{
                required: "Please provide OTP",
                minLength: {
                  value: 4,
                  message: "Please provide valid OTP"
                }
              }}
              name="otpField"
              render={({ field: { onChange, value } }) => <OTPInput onChange={onChange} value={value} hasErrored />}
            />
            {errors.otpField && <ErrorMsg msg={errors.otpField?.message} />}
          </SuiBox>
          <SuiBox mt={3} mb={1}>
            <SuiButton variant="gradient" fullWidth type="submit" setFixedColor="dark" data-cy="verify_OTP">
              Verify OTP
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </DialogContent>
    </Box>
  );
};

export default OTPField;
