import React from "react";
import { TableContext } from ".";

// icons
import { HiSwitchVertical } from "react-icons/hi";

// base styles
import typography from "assets/theme/base/typography";

// @mui imports
import { Box, TableRow, TableHead as MUI_TableHead, Checkbox } from "@mui/material";

// components
import { SuiBox } from "components";

// table helper
import { CalcPadding, handleCheckedAll } from "./helper";

// types
import { sharedTableData_T, row_PT } from "./types";

const TableHead = () => {
  const { size, fontWeightBold } = typography;

  const { columns, data, checked, setChecked, orderBy, order, setOrder, setOrderBy } = React.useContext(
    TableContext
  ) as sharedTableData_T;

  const handleCheckAllBehv = (newChecked: readonly string[]) => setChecked(newChecked);

  const handleRequestSort = (property: keyof row_PT) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const renderColumns = columns.map(({ name, align, width = "auto", sort = true, dataKey }, key) => {
    const { pl, pr } = CalcPadding(columns, key);

    return (
      <SuiBox
        key={name}
        component="th"
        width={width}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary">
        {key === 0 && (
          <Checkbox
            indeterminate={checked.length > 0 && checked.length < data.rows.length}
            checked={data.rows.length > 0 && checked.length === data.rows.length}
            onChange={(e) => handleCheckedAll(e, data.rows, handleCheckAllBehv)}
          />
        )}
        {sort ? (
          <Box
            component="span"
            onClick={() => handleRequestSort(dataKey)}
            sx={{ display: "inline-flex", alignItems: "center", cursor: "pointer", userSelect: "none" }}>
            {name.toUpperCase()}
            {orderBy === dataKey ? (
              <Box
                component="span"
                ml={0.3}
                sx={{
                  display: "inherit",
                  transition: "transform 0.4s",
                  transform: order === "desc" ? "rotate(180deg)" : ""
                }}>
                <HiSwitchVertical size={"0.85rem"} />
              </Box>
            ) : null}
          </Box>
        ) : (
          name.toUpperCase()
        )}
      </SuiBox>
    );
  });

  return (
    <Box component={MUI_TableHead} sx={{ position: "sticky", top: 0, zIndex: 1 }}>
      <TableRow>{renderColumns}</TableRow>
    </Box>
  );
};

export default TableHead;
