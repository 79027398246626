export const _newWindowLink = (url: string) => {
  const w = window.innerWidth,
    h = window.innerHeight;

  const perc: number = 18,
    topThreshold: number = 65;

  const calcW = w - (w * perc) / 100,
    calcH = h - (h * perc) / 100;

  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - calcW) / 2 / systemZoom + dualScreenLeft;
  const top = (height - calcH) / 2 / systemZoom + dualScreenTop;
  window.open(
    url,
    "",
    `scrollbars=yes,
     width=${calcW / systemZoom}, 
     height=${calcH / systemZoom}, 
     top=${top + topThreshold}, 
     left=${left}
     `
  );
};
