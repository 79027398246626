import React, { forwardRef } from "react";

// Custom styles fo SuiInput
import SuiInputRoot from "components/SuiInput/SuiInputRoot";

interface suiInp_types {
  size: "small" | "medium" | "large";
  error: boolean;
  success: boolean;
  disabled: boolean;

  // other props
  [rest: string]: any;
}

const SuiInput = forwardRef(
  ({ size = "medium", error = false, success = false, disabled = false, ...rest }: suiInp_types, ref: any) => {
    return <SuiInputRoot ref={ref} ownerState={{ size, error, success, disabled }} {...rest} />;
  }
);

export default SuiInput;
