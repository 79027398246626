import React from "react";
import { useForm, Controller } from "react-hook-form";

// components
import { BasicModal, SuiBox, SuiTypography, SuiButton, DropDown } from "components";

// mui imports
import { DialogContent, Box, FormControl, Checkbox } from "@mui/material";

// utils
import { changeLayoutOptions } from "utils/constants";

// theme types
import { theme_types } from "assets/theme";

// useContext
import { UseAppContext } from "context/App";

// utils
import { toastSuccess } from "utils/toast";

interface changeLayoutModal_PT {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface changeLayoutModal_Form {
  changeLayout: {
    value: string;
    label: string;
  };
  defaultLayout: boolean;
}

const ChangeLayoutModal = ({ open, setOpen }: changeLayoutModal_PT) => {
  const { setLayoutOrder } = UseAppContext();

  const {
    register,
    watch,
    formState: { errors },
    control,
    handleSubmit
  } = useForm<changeLayoutModal_Form>();

  const handleChangeLayout = (data: changeLayoutModal_Form) => {
    const { defaultLayout, changeLayout } = data;
    if (defaultLayout) {
      setLayoutOrder("");
      // clear localstroage
    } else {
      // set order in local storage
      setLayoutOrder(changeLayout.value);
    }
    toastSuccess("Layout successfully saved");
  };

  return (
    <BasicModal modalName="changeLayout" open={open} onClose={() => null} maxWidth="lg">
      <SuiBox variant="gradient" bgColor="dark">
        <DialogContent>
          <SuiTypography variant="body2" color="light" fontWeight="bold">
            Layout Settings
          </SuiTypography>

          {/* form content */}
          <Box component="form" role="form" onSubmit={handleSubmit(handleChangeLayout)} mt={1} ml={0.5} minHeight={"17rem"}>
            <Box my={1.8} display={"flex"} alignItems="center">
              <SuiTypography variant="nav_head" color="light">
                Change Layout
              </SuiTypography>
              <FormControl sx={{ ml: 0.8, width: "12rem" }} size="small">
                <Controller
                  control={control}
                  rules={{
                    required: watch("defaultLayout") ? false : "Please select layout"
                  }}
                  name="changeLayout"
                  render={({ field: { onChange, value } }) => (
                    <DropDown
                      optionsList={changeLayoutOptions}
                      placeholder="Select Layout"
                      isSearchable={false}
                      value={value}
                      onChange={onChange}
                      isDisabled={watch("defaultLayout")}
                      hasError={!!errors.changeLayout}
                    />
                  )}
                />
              </FormControl>
            </Box>

            <Box display="flex" alignItems="center">
              <SuiTypography component="label" variant="nav_head" color="light">
                Default Layout On Start
              </SuiTypography>
              <Box ml={1}>
                <Checkbox {...register("defaultLayout")} />
              </Box>
            </Box>

            {/* identfiers*/}
            <SuiBox
              borderRadius="sm"
              mt={1}
              px={2}
              pt={0.2}
              pb={0.6}
              sx={{
                border: ({ palette: { light } }: theme_types) => `1px solid ${light.main}`,
                "& span:not(:first-of-type)": { ml: 1 }
              }}>
              <SuiTypography component="span" variant="nav_head" color="light">
                S:&nbsp;Strong Match&ensp;|
              </SuiTypography>
              <SuiTypography component="span" variant="nav_head" color="light">
                W:&nbsp;Weak Match&ensp;|
              </SuiTypography>
              <SuiTypography component="span" variant="nav_head" color="light">
                N:&nbsp;No Match
              </SuiTypography>
            </SuiBox>

            {/* buttons */}
            <Box
              mt={2.5}
              display={"flex"}
              justifyContent="flex-end"
              sx={{
                "& button:not(:first-of-type)": {
                  ml: 1.5
                }
              }}>
              <SuiButton color="light" size="small" circular onClick={() => setOpen(false)}>
                Cancel
              </SuiButton>
              <SuiButton color="light" size="small" circular type="submit">
                Save
              </SuiButton>
            </Box>
          </Box>
        </DialogContent>
      </SuiBox>
    </BasicModal>
  );
};

export default ChangeLayoutModal;
