import React from "react";

import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { SuiBox, SuiTypography, CustomDropDown } from "components";
import { Box, Drawer, List, ListItemButton, ListItem, Collapse } from "@mui/material";

import { HiMenuAlt3 } from "react-icons/hi";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";

// static colors
import colors from "assets/theme/base/colors";

// assets
// import { LOGO } from "assets/images";

type nav_type = {
  isSticky: boolean;
};

const NavBar = ({ isSticky }: nav_type) => {
  const { white, dark } = colors;
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const [nestedServiceOpen, setNestedServiceOpen] = React.useState(true);

  const servicesMenuItems = [
    
    {
      label: "Carbon Reporting",
      id: "#carbon_section"
    },
    {
      label: "Carbon Reduction",
      id: "#carboff"
    },
    {
      label: "Renewable Energy",
      id: "#carboff"
    },
    {
      label: "KYC",
      id: "#kyc_section"
    },
    {
      label: "Compliance Advisory Services",
      id: "#Features_section"
    }
  ];

  const ServiceDropDown = () => {
    const servicesOptions = servicesMenuItems.map((item) => (
      <AnchorLink href={item.id} offset={100}>
        <SuiTypography sx={{ fontSize: "inherit" }}>{item.label}</SuiTypography>
      </AnchorLink>
    ));

    return (
      <CustomDropDown name="services" optionsList={servicesOptions} closeOnOptionClick>
        <Box
          px={2}
          pt={0.6}
          gap={1}
          display={"flex"}
          justifyContent="space-between"
          sx={{ color: isSticky ? dark.main : white.main }}>
          <SuiTypography variant="nav_head" sx={{ color: "inherit" }}>
            SERVICES
          </SuiTypography>
          <HiChevronDown size={"1rem"} />
        </Box>
      </CustomDropDown>
    );
  };

  const menuItems = [
    {
      label: "HOME",
      id: "#home_section"
    },
    {
      label: "SERVICES",
      id: "#Features_section",
      comp: <ServiceDropDown />
    },
    {
      label: "ABOUT",
      id: "#about_section"
    },
    {
      label: "MISSION",
      id: "#mission_section"
    },
    {
      label: "OPPORTUNITIES",
      id: "#opportunities_section"
    },
    {
      label: "CONTACT",
      id: "#contact_section"
    }
  ];

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <React.Fragment>
      <SuiBox
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          transition: "0.38s ease-in-out",
          py: isSticky ? "12px" : "20px",
          boxShadow: isSticky && "0px 3px 8px 0px rgba(43, 83, 135, 0.2)",
          background: isSticky ? white.main : "transparent"
        }}>
        <Box mx={{ xs: 3, lg: 5 }}>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center">
            {/* logo */}
            <Link to={"/"}>
              <SuiTypography variant="h6" sx={{ color: isSticky ? dark.main : white.main }}>
                ADAKAS
              </SuiTypography>
              {/* <img style={{ width: "2.5rem", verticalAlign: "middle" }} src={LOGO} alt="logo" /> */}
            </Link>

            <SuiBox display={{ xs: "none", lg: "flex" }} alignItems="center">
              {menuItems.map((item, i) =>
                item?.comp ? (
                  <React.Fragment key={i}>{item.comp}</React.Fragment>
                ) : (
                  <AnchorLink key={i} href={item.id} offset={100}>
                    <SuiTypography variant="nav_head" p={2} sx={{ color: isSticky ? dark.main : white.main }}>
                      {item.label}
                    </SuiTypography>
                  </AnchorLink>
                )
              )}
            </SuiBox>

            {/* for mobile */}
            <SuiBox
              display={{ xs: "inline-block", lg: "none" }}
              lineHeight={0}
              py={1.5}
              pl={1.5}
              sx={{ cursor: "pointer", color: isSticky ? dark.main : white.main }}
              onClick={() => setDrawerOpen(true)}>
              <HiMenuAlt3 fontSize={30} />
            </SuiBox>
          </SuiBox>
        </Box>
      </SuiBox>
      <Drawer anchor={"right"} open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {menuItems.map((item, i) => (
            <React.Fragment key={i}>
              {item?.comp ? (
                <ListItem
                  sx={{ px: "1rem", justifyContent: "space-between", cursor: "pointer" }}
                  onClick={() => setNestedServiceOpen((prevState) => !prevState)}>
                  <SuiTypography variant="nav_head" p={2} sx={{ color: dark.main }}>
                    {item.label}
                  </SuiTypography>
                  {nestedServiceOpen ? <HiChevronUp size={"1rem"} /> : <HiChevronDown size={"1rem"} />}
                </ListItem>
              ) : (
                <ListItemButton component="li" onClick={toggleDrawer(false)}>
                  <AnchorLink href={item.id} offset={100}>
                    <SuiTypography variant="nav_head" p={2} sx={{ color: dark.main }}>
                      {item.label}
                    </SuiTypography>
                  </AnchorLink>
                </ListItemButton>
              )}

              {item?.comp && (
                <Collapse component="li" in={nestedServiceOpen} timeout="auto" unmountOnExit>
                  <List disablePadding>
                    {servicesMenuItems.map((servItem, key) => (
                      <ListItemButton key={key} component="li" sx={{ pl: "1.75rem" }} onClick={toggleDrawer(false)}>
                        <AnchorLink href={servItem.id} offset={100}>
                          <SuiTypography variant="nav_head" p={2} sx={{ color: dark.main }}>
                            {servItem.label}
                          </SuiTypography>
                        </AnchorLink>
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
};

export default NavBar;
