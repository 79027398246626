import React from "react";
import { UseAppContext } from "context/App";
import { Link } from "react-router-dom";

import { theme_types } from "assets/theme";

// components
import { SuiBox, SuiTypography, SuiAvatar, CustomDropDown} from "components";

// mui imports
import { Box } from "@mui/material";

import { useNavigate } from "react-router-dom";

// nav compoents
import NavLink from "./Navlink";

// extra comp
import ToggleTheme from "extraComp/toggleTheme";

// icons
import { HiMenuAlt3, HiOutlineUser, HiOutlineDesktopComputer } from "react-icons/hi";

// assets
import { avator_img } from "assets/images";

const Navbar = () => {
  const { setWorflowModal, setUserPrefrenceModal, setChangeLayoutModal, mode, switchMode } = UseAppContext();
  const navigate = useNavigate();

  const handleLogout = () => navigate("/");
  const handleMultiTheme = () => {
    switchMode.toggleColorMode();
  };
  const avatorOptions = [
    <Box display={"flex"} alignItems="center" data-cy="avatorDropDown_toggle">
      <SuiTypography sx={{ fontSize: "inherit" }}>Switch Theme</SuiTypography>
      <ToggleTheme defaultChecked={mode === "dark" ? true : false} onChange={handleMultiTheme} />
    </Box>,
    <SuiTypography sx={{ fontSize: "inherit" }} onClick={handleLogout} data-cy="avatorDropDown_logout">
      Logout
    </SuiTypography>
  ];
  const profileOptions = [
    <SuiTypography sx={{ fontSize: "inherit" }} onClick={() => setChangeLayoutModal(true)}>
      Layouts
    </SuiTypography>,
    <SuiTypography sx={{ fontSize: "inherit" }} onClick={() => setUserPrefrenceModal(true)}>
      User Prefrences
    </SuiTypography>
  ];

  return (
    <SuiBox
      py={1.5}
      px={{ xs: 3, sm: 2, lg: 3 }}
      my={2}
      width="100%"
      borderRadius="sm"
      shadow="md"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={({ palette: { white }, functions: { rgba } }: theme_types) => ({
        backgroundColor: rgba(white.main, 0.85),
        backdropFilter: `saturate(200%) blur(30px)`,
        position: "sticky",
        top: 0,
        zIndex: 100
      })}
      data-cy="dashboardNav">
      <SuiBox display="flex" alignItems="center">
        <SuiBox component={Link} to={"/"} mr={1}>
          {/* <img style={{ width: "2.5rem", verticalAlign: "middle" }} src={LOGO} alt="logo" /> */}
          <SuiTypography variant="body2" sx={{ fontWeight: 600 }}>
            ADAKAS
          </SuiTypography>
        </SuiBox>
        {/* <SuiTypography variant="body2" sx={{ fontWeight: 500 }}>
          Adverse News Search
        </SuiTypography> */}
      </SuiBox>
      <SuiBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
        {/* <NavLink icon={<HiOutlineChartSquareBar />} name="dashboard" route="/dashboard" /> */}

        <NavLink
          icon={<HiOutlineDesktopComputer />}
          name="admin"
          navType={"button"}
          onNavClick={{
            triggerAction: () => setWorflowModal(true)
          }}
          dataCy="open_WorkflowModal"
        />
        <CustomDropDown name="profile" optionsList={profileOptions} listContStyles={{ right: "1rem" }} closeOnOptionClick>
          <NavLink
            icon={<HiOutlineUser />}
            name="profile"
            navType={"dropDown"}
            onNavClick={{
              triggerAction: () => setUserPrefrenceModal(true)
            }}
          />
        </CustomDropDown>

        <CustomDropDown name="avator" optionsList={avatorOptions} listContStyles={{ right: 0 }} dataCy="avatorDropDown">
          <SuiAvatar variant="rounded" src={avator_img} alt="avator" shadow="md" size="sm" />
        </CustomDropDown>
      </SuiBox>

      {/* for mobile */}
      <SuiBox
        display={{ xs: "inline-block", lg: "none" }}
        lineHeight={0}
        py={1.5}
        pl={1.5}
        color="inherit"
        sx={{ cursor: "pointer" }}>
        <HiMenuAlt3 />
      </SuiBox>
    </SuiBox>
  );
};

export default Navbar;
