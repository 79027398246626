import React from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { theme_types } from "assets/theme";

// mui imports
import { Box, Fade } from "@mui/material";

// components
import { SuiBox } from "components";

interface dropDown_PT {
  name: string;
  children: React.ReactNode;
  optionsList: React.ReactNode[];
  closeOnOptionClick?: boolean;
  listContStyles?: {} | null;
  dataCy?: string;
}

const CustomDropDown = ({
  name,
  children,
  optionsList,
  closeOnOptionClick = false,
  listContStyles = null,
  dataCy
}: dropDown_PT) => {
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);

  const handleDropDownClick = (): void => setOpenMenu(!openMenu);
  const closeDropDown = (): void => setOpenMenu(false);
  const ref = useOnclickOutside(() => closeDropDown());

  const defaultListStyles = {
    position: "absolute",
    overflowX: "hidden",
    mt: "0.6rem",
    py: "0.7rem",
    width: "max-content",
    minWidth: "7rem",
    maxWidth: "15rem",
    minHeight: "2.3rem",
    maxHeight: "12rem"
  };

  return (
    <Box ref={ref} id={`customDropDown_${name}`} position="relative" data-cy={dataCy}>
      {/* active dropdown on */}
      <Box component="span" onClick={handleDropDownClick} sx={{ cursor: "pointer" }}>
        {children}
      </Box>
      {/* options list container */}
      <Fade in={openMenu}>
        <SuiBox
          bgColor="white"
          borderRadius="sm"
          shadow="lg"
          id={`customDropDownList_${name}`}
          onClick={closeOnOptionClick ? closeDropDown : () => null}
          sx={listContStyles ? { ...defaultListStyles, ...listContStyles } : defaultListStyles}>
          {optionsList.map((option, i) => (
            <SuiBox
              key={i}
              sx={({ palette: { grey } }: theme_types) => ({
                px: "0.8rem",
                cursor: "pointer",
                fontSize: "0.82rem",
                "&:not(:first-of-type)": { mt: "0.4rem" },
                "&:hover": { backgroundColor: grey[300] }
              })}>
              {option}
            </SuiBox>
          ))}
        </SuiBox>
      </Fade>
    </Box>
  );
};

export default CustomDropDown;
