import React from "react";

// components
import { SuiTypography } from "components";

// mui imports
import { Box } from "@mui/material";

interface ErrorMsg_PT {
  msg: string | undefined;
}

const ErrorMsg = ({ msg }: ErrorMsg_PT) => {
  return (
    <SuiTypography component={Box} variant="caption" color="error" my={1.1}>
      {msg}
    </SuiTypography>
  );
};

export default ErrorMsg;
