import React from "react";

// components
import { SuiTypography, SuiBox, SuiInput, SuiButton } from "components";
import ErrorMsg from "./ErrorMsg";

// mui imports
import { Box, DialogTitle, DialogContent } from "@mui/material";

import { useForm } from "react-hook-form";

// regex
import { email_regex } from "utils/helper/_regexExp";

// static colors
import colors from "assets/theme/base/colors";

interface SignUp_PT {
  toggleScreen: any;
}

interface signUp_FormInp {
  name: string;
  email: string;
  password: string;
}

const SignUpFields = ({ toggleScreen }: SignUp_PT) => {
  const { dark, text } = colors;

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<signUp_FormInp>();

  const handleSignUp = (data: signUp_FormInp) => {
    console.debug(data);
    // sign up request

    toggleScreen();
  };

  return (
    <Box>
      <DialogTitle>
        <SuiTypography variant="h4" component="span" sx={{ color: dark.main }}>
          Register an Account
        </SuiTypography>
        <br />
        <SuiTypography variant="body3" mt={1} sx={{ color: dark.main }}>
          Get your account registered in couple of mins
        </SuiTypography>
      </DialogTitle>
      <DialogContent>
        <SuiBox component="form" role="form" onSubmit={handleSubmit(handleSignUp)}>
          <SuiBox mb={1.5}>
            <SuiInput
              type="text"
              placeholder="Name*"
              {...register("name", {
                required: "Field is required",
                minLength: {
                  value: 4,
                  message: "Atleast 4 characters"
                }
              })}
              error={errors.name?.message}
            />
            {errors.name && <ErrorMsg msg={errors.name?.message} />}
          </SuiBox>
          <SuiBox mb={1.5}>
            <SuiInput
              type="text"
              placeholder="Email*"
              {...register("email", {
                required: "Field is required",
                pattern: {
                  value: email_regex,
                  message: "Please provide valid email"
                }
              })}
              error={errors.email?.message}
            />
            {errors.email && <ErrorMsg msg={errors.email?.message} />}
          </SuiBox>
          <SuiBox mb={1}>
            <SuiInput
              type="password"
              placeholder="Password*"
              {...register("password", {
                required: "Field is required"
              })}
              error={errors.password?.message}
            />
            {errors.password && <ErrorMsg msg={errors.password?.message} />}
          </SuiBox>
          <SuiBox mt={3} mb={1}>
            <SuiButton variant="gradient" fullWidth type="submit" setFixedColor="dark">
              sign up
            </SuiButton>
          </SuiBox>
          <SuiBox mt={1} textAlign="center">
            <SuiTypography variant="button" fontWeight="regular" sx={{ color: text.main }}>
              Already have an account?&nbsp;
              <SuiTypography
                variant="button"
                color="dark"
                fontWeight="medium"
                textGradient
                sx={{
                  color: dark.main,
                  cursor: "pointer"
                }}
                onClick={toggleScreen}>
                Sign In
              </SuiTypography>
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </DialogContent>
    </Box>
  );
};

export default SignUpFields;
