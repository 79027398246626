import { Palette } from "assets/theme/base/colorsT";

//  React base styles
import borders, { makeBorderColor } from "assets/theme/base/borders";

//  React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const { borderWidth } = borders;

const radio = (palette: Palette) => {
  const { transparent, gradients, info } = palette;
  const borderColor = makeBorderColor(palette);
  return {
    styleOverrides: {
      root: {
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: pxToRem(20),
        height: pxToRem(20),
        marginRight: pxToRem(6),
        padding: 0,
        color: transparent.main,
        border: `${borderWidth[1]} solid ${borderColor}`,
        borderRadius: "50%",
        transition: "all 250ms ease",

        "&:hover": {
          backgroundColor: transparent.main
        },

        "& .MuiSvgIcon-root": {
          fill: transparent.main
        },

        "&.Mui-focusVisible": {
          border: `${borderWidth[2]} solid ${info.main} !important`
        }
      },

      colorPrimary: {
        backgroundColor: transparent.main,

        "&.Mui-checked": {
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='3' fill='%23fff' /%3E%3C/svg%3E"), ${linearGradient(
            gradients.dark.main,
            gradients.dark.state
          )}`,
          borderColor: gradients.dark.main
        },

        "&:hover": {
          backgroundColor: transparent.main
        }
      },

      colorSecondary: {
        backgroundColor: transparent.main,

        "&.Mui-checked": {
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='3' fill='%23fff' /%3E%3C/svg%3E"), ${linearGradient(
            gradients.dark.main,
            gradients.dark.state
          )}`,
          borderColor: gradients.dark.main
        },

        "&:hover": {
          backgroundColor: transparent.main
        }
      }
    }
  };
};

export default radio;
