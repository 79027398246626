import React from "react";
import ReactDOM from "react-dom";

import App from "app/App";

import { BrowserRouter as Router } from "react-router-dom";

// store
import store from "store";
import { Provider } from "react-redux";

// context
import { AppProvider } from "context/App";

const ReactApp = () => (
  <Provider store={store}>
    <Router>
      <AppProvider>
        <App />
      </AppProvider>
    </Router>
  </Provider>
);

ReactDOM.render(<ReactApp />, document.getElementById("root"));
