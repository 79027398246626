import React, { useState } from "react";

// @mui imports
import { Box, TableContainer, Table, TablePagination } from "@mui/material";

// table Components
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import HeaderOptions from "./HeaderOptions";

// types
import { sharedTableData_T, table_PT, order_T, row_PT } from "./types";

// context
export const TableContext = React.createContext<sharedTableData_T | null>(null);
TableContext.displayName = "TableContext";

const CustomizedTable = ({ label, columns, data, dataCy = undefined  }: table_PT) => {
  // use States
  const [checked, setChecked] = useState<readonly string[]>([]);
  const [selectedRow, setSelectRow] = useState<readonly string[]>([]);
  const [order, setOrder] = React.useState<order_T>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof row_PT>("id");
  const [rowsPerPage] = React.useState<number>(20);
  const [page, setPage] = React.useState<number>(0);

  // context Value
  const initialContextValue: sharedTableData_T = {
    label,
    columns,
    data,
    checked,
    setChecked,
    selectedRow,
    setSelectRow,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    page
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage);

  return (
    <Box aria-label="Customized table" position={"relative"} id={`table_${label}`}>
      <TableContext.Provider value={initialContextValue}>
        <HeaderOptions dataCy={dataCy} />
        <TableContainer sx={{ maxHeight: "12rem", minHeight: "12rem" }}>
          <Table data-cy={dataCy}>
            <TableHead />
            <TableBody />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component={Box}
          count={data.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </TableContext.Provider>
    </Box>
  );
};

export default CustomizedTable;
