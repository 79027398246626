import React from "react";

// @mui material components
import { Grid, Tooltip, Box, useTheme } from "@mui/material";

// base styles
import borders from "assets/theme/base/borders";

// components
import { SuiBox, SuiTypography } from "components";
interface statsCard_PT {
  bgColor?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  title: {
    fontWeight: "light" | "regular" | "medium" | "bold";
    text: string;
  };
  count?: string | number;
  percentage?: {
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "white";
    text: string | number;
  };
  icon?: {
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
    component: React.ReactNode | boolean;
  };
  tooltipTxt: string;
  // other props
  [rest: string]: any;
}

function StatsCard({
  bgColor = "white",
  title,
  count,
  percentage = {
    color: "success",
    text: ""
  },
  icon = {
    color: "primary",
    component: false
  },
  tooltipTxt,
  ...rest
}: statsCard_PT) {
  const theme = useTheme();
  const { borderWidth } = borders;

  return (
    <SuiBox
      bgColor={bgColor}
      py={1.5}
      px={2}
      sx={{ border: `${borderWidth[1]} solid ${theme.palette.grey[300]}`, borderRadius: "0.4rem" }}
      {...rest}>
      <Grid container alignItems="center">
        <Grid item xs={9}>
          <SuiBox ml={1} lineHeight={1}>
            <SuiTypography
              variant="button"
              color={bgColor === "white" ? "text" : "white"}
              opacity={bgColor === "white" ? 1 : 0.7}
              textTransform="capitalize"
              fontWeight={title.fontWeight}>
              {title.text}
            </SuiTypography>
            <SuiTypography variant="h6" color={bgColor === "white" ? "dark" : "white"}>
              {count}&ensp;
              <SuiTypography variant="body3" color={percentage.color}>
                {percentage.text}
              </SuiTypography>
            </SuiTypography>
          </SuiBox>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Tooltip title={tooltipTxt}>
              <SuiBox color={icon.color} fontSize="1.5rem" component="span" display="inline-flex">
                {icon.component}
              </SuiBox>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default StatsCard;
