//  React Base Styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

//  PRO helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { inputColors } = colors;
const { borderWidth, borderRadius } = borders;

const input = {
  styleOverrides: {
    root: {
      display: "flex",
      padding: `${pxToRem(8)} ${pxToRem(28)} ${pxToRem(8)} ${pxToRem(12)}`,
      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
      borderRadius: `${borderRadius.md}`,

      "& fieldset": {
        border: "none"
      }
    },

    input: {
      height: pxToRem(22),
      width: "max-content"
    },

    inputSizeSmall: {
      height: pxToRem(14)
    }
  }
};

export default input;
