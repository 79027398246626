import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// local service
import { getUsers } from "services";

export const getUsersAction = createAsyncThunk("getUsers", async ({ page, limit }: any, thunkAPI) => {
  const res = await getUsers();
  return res?.data?.data;
});

interface Tstate {
  data: any;
  load: boolean;
  error: any;
}

const initialState: Tstate = {
  data: "",
  load: false,
  error: false
};

const UsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getUsersAction.pending, (state, action) => {
      state.load = true;
    });
    builder.addCase(getUsersAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.load = false;
    });
    builder.addCase(getUsersAction.rejected, (state, action) => {
      state.load = false;
      state.error = action?.error;
    });
  }
});

export default UsersSlice.reducer;
