import React from "react";

// mui imports
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

interface b_modal_PT {
  modalName: string;
  open: boolean;
  onClose: any;
  children: React.ReactNode;
  noTransition?: boolean;

  // other props
  [rest: string]: any;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="down" ref={ref} {...props} />
);

const BasicModal = ({ modalName, open, onClose, children, noTransition = false, ...rest }: b_modal_PT) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={noTransition ? () => null : Transition}
      onClose={onClose}
      aria-describedby={`modal_${modalName}`}
      {...rest}
    >
      {children}
    </Dialog>
  );
};

export default BasicModal;
