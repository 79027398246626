// import React from "react";

// // components
// import { SuiTypography, SuiButton } from "components";

// // mui imports
// import { Box, InputBase } from "@mui/material";

// import { useCSVReader } from "react-papaparse";

// // utils
// import { csvExportHeader } from "utils/constants";
// import { toastSuccess, toastError } from "utils/toast";

// // types
// import { tab_PT } from ".";

// const ImportData = ({ handleClose }: tab_PT) => {
//   // const uploadInputRef = React.useRef<any>(null);
//   // const [fileName, setFileName] = React.useState<string>("");

//   // const handleFileChange = (e: any) => {
//   //   const fileList = e.target.files;
//   //   console.log(fileList[0]);

//   //   setFileName(fileList[0]?.name);
//   //   // console.log(URL.createObjectURL(fileList?.files[0]));
//   // };

//   const handleFileImport = (results: any) => {
//     console.log(results);

//     const header = results?.data[0];
//     let validFile = true;
//     csvExportHeader.forEach(({ label }, i) => {
//       console.log(i);

//       if (label !== header[i]) {
//         validFile = false;
//         return;
//       }
//     });

//     if (validFile) toastSuccess("Valid file");
//     else toastError("Invalid File");
//   };

//   const { CSVReader } = useCSVReader();

//   return (
//     <Box mt={2.5}>
//       <SuiTypography variant="body2" color="light" fontWeight="bold" ml={0.5}>
//         Import Data
//       </SuiTypography>
//       {/* form content */}
//       <Box mt={1}>
//         <CSVReader
//           onUploadAccepted={handleFileImport}
//           onFileLoaded={(data: any, fileInfo: any) => console.log(data, fileInfo)}
//           >
//           {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => (
//             <>
//               <Box display="flex" alignItems={"center"}>
//                 <SuiTypography component="label" variant="nav_head" color="light">
//                   Please select file to import
//                 </SuiTypography>
//                 <Box ml={1.2} flex={1}>
//                   <InputBase value={acceptedFile ? acceptedFile.name : ""} readOnly />
//                 </Box>
//               </Box>

//               {/* <ProgressBar style={styles.progressBarBackgroundColor} /> */}
//               <Box
//                 mt={2.5}
//                 display={"flex"}
//                 justifyContent="flex-end"
//                 sx={{
//                   "& button:not(:first-of-type)": {
//                     ml: 1.5
//                   }
//                 }}>
//                 <SuiButton color="light" size="small" circular onClick={handleClose}>
//                   Cancel
//                 </SuiButton>
//                 <SuiButton color="light" size="small" circular {...getRootProps()}>
//                   Browse
//                 </SuiButton>
//                 <SuiButton color="light" size="small" circular disabled={!!!acceptedFile}>
//                   Import
//                 </SuiButton>
//               </Box>
//             </>
//           )}
//         </CSVReader>
//       </Box>
//     </Box>
//   );
// };

// export default ImportData;

import React from "react";

// components
import { SuiTypography, SuiButton } from "components";

// mui imports
import { Box, InputBase } from "@mui/material";

// types
import { tab_PT } from ".";

import Papa from "papaparse";

// utils
import { csvExportHeader } from "utils/constants";
import { toastSuccess, toastError } from "utils/toast";

const ImportData = ({ handleClose }: tab_PT) => {
  const uploadInputRef = React.useRef<any>(null);
  const [fileName, setFileName] = React.useState<string>("");

  const handleFileChange = (e: any) => {
    const fileList: any = document.getElementById("importFile");
    const file = fileList?.files[0];
    if (file?.type === "text/csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const fileHeader = Object.keys(results?.data[0] as object);
          let validFile = true;
          csvExportHeader.forEach(({ label }, i) => {
            if (label !== fileHeader[i]) {
              validFile = false;
              return;
            }
          });
          if (validFile) {
            setFileName(fileList?.files[0]?.name);
            toastSuccess("Valid file");
          } else toastError("Invalid columns");
        }
      });
    } else {
      toastError("Invalid file type or extension");
    }
    // console.log(URL.createObjectURL(fileList?.files[0]));
  };

  const handleImport = () => {
    toastSuccess("File Imported Successfully");
    handleClose();
  };

  return (
    <Box mt={2.5}>
      <SuiTypography variant="body2" color="light" fontWeight="bold" ml={0.5}>
        Import Data
      </SuiTypography>
      {/* form content */}
      <Box mt={1}>
        <Box display="flex" alignItems={"center"}>
          <SuiTypography component="label" variant="nav_head" color="light">
            File Name
          </SuiTypography>
          <Box ml={1.2} flex={1}>
            <InputBase value={fileName} placeholder="Please Select a File" fullWidth readOnly />
          </Box>
        </Box>

        <input
          id="importFile"
          ref={uploadInputRef}
          type="file"
          // accept=".csv"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        {/* buttons */}
        <Box
          mt={2.5}
          display={"flex"}
          justifyContent="flex-end"
          sx={{
            "& button:not(:first-of-type)": {
              ml: 1.5
            }
          }}>
          <SuiButton color="light" size="small" circular onClick={handleClose}>
            Cancel
          </SuiButton>
          <SuiButton color="light" size="small" circular onClick={() => uploadInputRef.current && uploadInputRef.current.click()}>
            Browse
          </SuiButton>
          <SuiButton color="light" size="small" circular disabled={!fileName} onClick={handleImport}>
            Import
          </SuiButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ImportData;
