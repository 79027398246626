import { Palette } from "assets/theme/base/colorsT";

//  React base styles
import borders from "assets/theme/base/borders";

//  React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;
const tableHead = (palette: Palette) => {
  const { white } = palette;
  return {
    styleOverrides: {
      root: {
        background: white.main,
        padding: `${pxToRem(16)} ${pxToRem(16)} 0  ${pxToRem(16)}`,
        borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`
      }
    }
  };
};

export default tableHead;
