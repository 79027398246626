import React from "react";

import { Link } from "react-router-dom";

import { SuiBox, SuiTypography } from "components";
import { Box } from "@mui/material";

// static colors
import colors from "assets/theme/base/colors";

type nav_type = {
  isSticky: boolean;
};

const NavBar = ({ isSticky }: nav_type) => {
  const { white, dark } = colors;

  return (
    <SuiBox
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        transition: "0.38s ease-in-out",
        py: isSticky ? "12px" : "20px",
        boxShadow: isSticky && "0px 3px 8px 0px rgba(43, 83, 135, 0.2)",
        background: isSticky? white.main: "transparent"
      }}>
      <Box mx={{ xs: 3, lg: 5 }}>
        <SuiBox display="flex" justifyContent="space-between" alignItems="center">
          {/* logo */}
          <Link to={"/"}>
            <SuiTypography variant="h6" sx={{ color: isSticky ? dark.main : white.main }}>
              ADAKAS
            </SuiTypography>
          </Link>
        </SuiBox>
      </Box>
    </SuiBox>
  );
};

export default NavBar;
