import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { lineChartData, horiz_barChartData, pieChartData } from "utils/constants";

// json
import risKScoreGraph from "json/risk_score.json";
import barChart from "json/bar_chart.json";
import pirChart from "json/pie_chart.json";

// types
import { row_PT } from "extraComp/table/types";

// define types
export type statusIcon_T = "New" | "Pending" | "In Progress" | "Pending" | "Completed" | "Cancelled";
export type relvance_T = {
  entity_name: string;
  score: { entity_name: string; value: number };
  status: { entity_name: string; value: statusIcon_T };
};
export type chartData_T = {
  labels: string[];
  data: number[];
};

export interface getRowData_T {
  relvance: relvance_T;
  riskScore: chartData_T;
  barChart: chartData_T;
  piChart: chartData_T;
}

const initialState: getRowData_T = {
  relvance: {
    entity_name: "Microsoft",
    score: { entity_name: "Relevance Score", value: 6.7 },
    status: { entity_name: "Strong Match", value: "Completed" }
  },
  riskScore: lineChartData,
  barChart: horiz_barChartData,
  piChart: pieChartData
};

const getRowData = createSlice({
  name: "rowData",
  initialState,
  reducers: {
    rowBaseData: (state, action: PayloadAction<row_PT>) => {
      const row = action.payload;
      const relvance = {
        entity_name: row.entity_name,
        score: { entity_name: "Relevance Score", value: row.risk_score },
        status: { entity_name: row.status, value: row.work_status }
      };

      return {
        relvance,
        riskScore: risKScoreGraph,
        barChart: barChart,
        piChart: pirChart
      };
    }
  }
});

export const { rowBaseData } = getRowData.actions;

export default getRowData.reducer;
