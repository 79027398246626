import React, { useMemo } from "react";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// components
import { SuiBox, SuiTypography } from "components";

// horizontalBarChart configurations
import configs from "extraComp/charts/horizontalBarChart/configs";

// base styles
import colors from "assets/theme/base/colors";

interface barChart_types {
  title?: string;
  description?: string | React.ReactNode;
  height?: string | number;
  chart?: {
    labels: string[];
    datasets: any[];
  };
}

type BarChar_dataSet_type = {
  label?: string;
  color: "dark" | "info";
  data?: number[];
};

const HorizontalBarChart = ({ title, description, height = "19.125rem", chart }: barChart_types) => {
  const chartDatasets = chart!.datasets
    ? chart!.datasets.map((dataset: BarChar_dataSet_type) => ({
        ...dataset,
        weight: 5,
        borderWidth: 0,
        borderRadius: 4,
        backgroundColor: colors[dataset.color] ? colors[dataset.color || "dark"].main : colors.dark.main,
        fill: false,
        maxBarThickness: 35
      }))
    : [];

  const { data, options } = configs(chart!.labels || [], chartDatasets);

  const renderChart = (
    <SuiBox p={2}>
      {title || description ? (
        <SuiBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <SuiBox mb={1}>
              <SuiTypography variant="h6">{title}</SuiTypography>
            </SuiBox>
          )}
          <SuiBox mb={2}>
            <SuiTypography component="div" variant="button" fontWeight="regular" color="text">
              {description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      ) : null}
      {useMemo(
        () => (
          <SuiBox height={height}>
            <Bar data={data} options={options} />
          </SuiBox>
        ),
        [data, options, height]
      )}
    </SuiBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
};

export default HorizontalBarChart;
