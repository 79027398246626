import { Palette } from "assets/theme/base/colorsT";

//  React base styles
import borders, { makeBorderColor } from "assets/theme/base/borders";

const { borderWidth } = borders;

const stepConnector = (palette: Palette) => {
  const { dark } = palette;
  const borderColor = makeBorderColor(palette);
  return {
    styleOverrides: {
      root: {
        color: borderColor,
        transition: "all 200ms linear",

        "&.Mui-active": {
          color: dark.main
        },

        "&.Mui-completed": {
          color: dark.main
        }
      },

      alternativeLabel: {
        top: "14%",
        left: "-50%",
        right: "50%"
      },

      line: {
        borderWidth: `${borderWidth[2]} !important`,
        borderColor: "currentColor"
      }
    }
  };
};

export default stepConnector;
