import { forwardRef } from "react";

// Custom styles for SuiAvatar
import SuiAvatarRoot from "./SuiAvatarRoot";

interface SuiAvatar_types {
  bgColor?: "transparent" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  shadow?: "none" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "inset";

  // other props
  [rest: string]: any;
}

const SuiAvatar = forwardRef(({ bgColor = "transparent", size = "md", shadow = "none", ...rest }: SuiAvatar_types, ref: any) => (
  <SuiAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />
));

export default SuiAvatar;
