import React from "react";

// components
import { BasicModal } from "components";

// mui imports
import { DialogTitle, DialogContent, Box } from "@mui/material";

import { HiOutlineX } from "react-icons/hi";

interface FullscreenModal_PT {
  comp: React.ReactNode;
  setComp: any;
}

const FullscreenModal = ({ comp, setComp }: FullscreenModal_PT) => {
  return (
    <BasicModal modalName="Fullscreen" open={!!comp} onClose={() => null} maxWidth="xl" data-cy="max_modal">
      <Box minWidth={"70%"}>
        <DialogTitle>
          <Box display="flex" justifyContent={"flex-end"}>
            <Box
              display="flex"
              sx={{
                transition: "transform 0.4s",
                cursor: "pointer",
                "&:hover": {
                  transform: "rotate(90deg)"
                }
              }}
              onClick={() => setComp(null)}
              data-cy="max_modal_close">
              <HiOutlineX size={"1.4rem"} />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent={"center"} alignItems="center">
            <Box
              position={"relative"}
              overflow="auto"
              py={1}
              sx={{
                "& div div.MuiTableContainer-root": {
                  minHeight: "57vh"
                }
              }}>
              {comp}
            </Box>
          </Box>
        </DialogContent>
      </Box>
    </BasicModal>
  );
};

export default FullscreenModal;
