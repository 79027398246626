import React from "react";

// modals
import FullscreenModal from "extraComp/modals/fullscreen";
import ActionModal from "extraComp/modals/action";
import AdminWorkFlow from "extraComp/modals/workflow";
import ChangeLayoutModal from "extraComp/modals/changeLayout";
import UserPrefrenceModal from "extraComp/modals/UserPrefrence";

// context
import { UseAppContext } from "context/App";

const ModalsHandler = () => {
  const {
    fullscreenComp,
    setFullscreenComp,
    actionModal,
    setActionModal,
    worflowModal,
    setWorflowModal,
    changeLayoutModal,
    setChangeLayoutModal,
    userPrefrenceModal,
    setUserPrefrenceModal
  } = UseAppContext();
  return (
    <>
      <FullscreenModal comp={fullscreenComp} setComp={setFullscreenComp} />
      <ActionModal open={actionModal} setOpen={setActionModal} />
      <AdminWorkFlow open={worflowModal} setOpen={setWorflowModal} />
      <ChangeLayoutModal open={changeLayoutModal} setOpen={setChangeLayoutModal} />
      <UserPrefrenceModal open={userPrefrenceModal} setOpen={setUserPrefrenceModal} />
    </>
  );
};

export default ModalsHandler;
