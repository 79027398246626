import React from "react";
import { useForm } from "react-hook-form";

// components
import { SuiTypography, SuiBox, SuiInput, SuiButton } from "components";

// mui components
import { Grid } from "@mui/material";

// regex
import { email_regex } from "utils/helper/_regexExp";

// email service
import { emailService } from "services";

// static colors
import colors from "assets/theme/base/colors";

interface contactForm_T {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
}

const ContactForm = () => {
  const { dark } = colors;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<contactForm_T>();

  const handleContactForm = (data: contactForm_T) => {
    (async (_) => {
      try {
        const isSent = await emailService("template_tgfarn6", data, "Thank you for contacting us");
        if (isSent === "sent") reset();
      } catch (error) {
        console.log(error);
      }
    })();
  };

  return (
    <SuiBox component="form" role="form" onSubmit={handleSubmit(handleContactForm)}>
      <Grid container spacing={1.5} mb={1}>
        <Grid item xs={12} md={6}>
          <SuiBox mb={0.5} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold" sx={{ color: dark.main }}>
              First Name*
            </SuiTypography>
          </SuiBox>
          <SuiInput
            type="text"
            placeholder="First Name"
            {...register("firstName", {
              required: "Field is required"
            })}
            error={errors.firstName?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SuiBox mb={0.5} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold" sx={{ color: dark.main }}>
              Last Name*
            </SuiTypography>
          </SuiBox>
          <SuiInput
            type="text"
            placeholder="Last Name"
            {...register("lastName", {
              required: "Field is required"
            })}
            error={errors.lastName?.message}
          />
        </Grid>
      </Grid>

      <SuiBox>
        <SuiBox mb={0.5} ml={0.5}>
          <SuiTypography component="label" variant="caption" fontWeight="bold" sx={{ color: dark.main }}>
            Email*
          </SuiTypography>
        </SuiBox>
        <SuiInput
          type="text"
          placeholder="Email"
          {...register("email", {
            required: "Field is required",
            pattern: {
              value: email_regex,
              message: "Please provide valid email"
            }
          })}
          error={errors.email?.message}
        />
      </SuiBox>
      <SuiBox mb={1}>
        <SuiBox mb={0.5} ml={0.5}>
          <SuiTypography component="label" variant="caption" fontWeight="bold" sx={{ color: dark.main }}>
            Subject*
          </SuiTypography>
        </SuiBox>
        <SuiInput
          type="text"
          placeholder="Subject here"
          {...register("subject", {
            required: "Field is required"
          })}
          error={errors.subject?.message}
        />
      </SuiBox>
      <SuiBox mb={1}>
        <SuiBox mb={0.5} ml={0.5}>
          <SuiTypography component="label" variant="caption" fontWeight="bold" sx={{ color: dark.main }}>
            Message*
          </SuiTypography>
        </SuiBox>
        <SuiInput
          type="text"
          placeholder="Your message here"
          multiline
          rows={5}
          {...register("message", {
            required: "Field is required"
          })}
          error={errors.message?.message}
        />
      </SuiBox>
      <SuiBox mt={3} mb={1}>
        <SuiButton variant="gradient" type="submit" setFixedColor="dark">
          Submit Request
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
};

export default ContactForm;
