import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// local service
import { getSearchData } from "services";

export type matchesData_T = {
  data: {
    strongData: [];
    weakData: [];
    noData: [];
  };
  load: boolean;
  error: boolean | string | undefined;
};

export const getSearchedDataAction = createAsyncThunk("getSearchData", async (search_date: string, thunkAPI) => {
  return await getSearchData(search_date);
});

const initialState: matchesData_T = {
  data: {
    strongData: [],
    weakData: [],
    noData: []
  },
  load: false,
  error: false
};

const SearchedDataSlice = createSlice({
  name: "searchedData",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getSearchedDataAction.pending, (state) => {
        state.load = true;
      })
      .addCase(getSearchedDataAction.fulfilled, (state, action) => {
        const searchedData = action.payload as [];

        // filter data according to status
        const strongData = searchedData.filter((row: any) => row?.status === "Strong Match") as [];
        const weakData = searchedData.filter((row: any) => row?.status === "weak Matches") as [];
        const noData = searchedData.filter((row: any) => row?.status === "No Match") as [];
        
        state.data = { strongData, weakData, noData };
        state.load = false;
      })
      .addCase(getSearchedDataAction.rejected, (state, action) => {
        state.load = false;
        state.error = action.error.message;
      });
  }
});

export default SearchedDataSlice.reducer;
