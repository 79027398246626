import React from "react";

// components
import { SuiTypography, SuiBox, SuiInput, SuiButton } from "components";
import ErrorMsg from "./ErrorMsg";

// mui imports
import { Box, DialogTitle, DialogContent } from "@mui/material";

import { useForm } from "react-hook-form";

// regex
import { email_regex } from "utils/helper/_regexExp";

// sample Data
import usersData from "json/users.json";

// static colors
import colors from "assets/theme/base/colors";

interface SignIn_PT {
  setOtp: any;
  toggleScreen: any;
}

interface signIn_FormInp {
  email: string;
  password: string;
  invalidInput: string;
}

const SignInFields = ({ setOtp, toggleScreen }: SignIn_PT) => {
  const { dark, text } = colors;

  const {
    register,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit
  } = useForm<signIn_FormInp>({
    defaultValues: {
      email: "",
      password: ""
    }
  });

  // submitHandlers
  const handleSignIn = (data: signIn_FormInp) => {
    // check user credentials
    const user = usersData.filter((user) => user.email === data.email && user.password === data.password);
    if (user.length > 0) setOtp(user[0]?.otp);
    else {
      setError("invalidInput", {
        type: "mannual",
        message: "Invalid email or password"
      });
    }
  };

  // Clear error on invalid credentails
  React.useEffect(() => {
    const subscription = watch(() => errors.invalidInput && clearErrors("invalidInput"));

    return () => subscription.unsubscribe();
  }, [watch, errors.invalidInput, clearErrors]);

  return (
    <Box>
      <DialogTitle>
        <SuiTypography variant="h4" component="span" sx={{ color: dark.main }}>
          Welcome back
        </SuiTypography>
        <br />
        <SuiTypography variant="body3" mt={1} sx={{ color: dark.main }}>
          Enter your email and password to sign in
        </SuiTypography>
      </DialogTitle>
      <DialogContent>
        <SuiBox component="form" role="form" onSubmit={handleSubmit(handleSignIn)}>
          <SuiBox mb={1}>
            <SuiBox mb={0.5} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold" sx={{ color: dark.main }}>
                Email
              </SuiTypography>
            </SuiBox>
            <SuiInput
              type="text"
              placeholder="Email"
              {...register("email", {
                required: "Field is required",
                pattern: {
                  value: email_regex,
                  message: "Please provide valid email"
                }
              })}
              error={errors.email?.message}
              data-cy="sign_in_emailField"
            />
            {errors.email && <ErrorMsg msg={errors.email?.message} />}
          </SuiBox>
          <SuiBox mb={1}>
            <SuiBox mb={0.5} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold" sx={{ color: dark.main }}>
                Password
              </SuiTypography>
            </SuiBox>
            <SuiInput
              type="password"
              placeholder="Password"
              {...register("password", {
                required: "Field is required"
              })}
              error={errors.password?.message}
              data-cy="sign_in_PasswordField"
            />
            {errors.password && <ErrorMsg msg={errors.password?.message} />}
            {errors.invalidInput && <ErrorMsg msg={errors.invalidInput?.message} />}
          </SuiBox>
          <SuiBox mt={3} mb={1}>
            <SuiButton variant="gradient" fullWidth type="submit" setFixedColor="dark" data-cy="sign_in">
              sign in
            </SuiButton>
          </SuiBox>
          <SuiBox mt={1} textAlign="center">
            <SuiTypography variant="button" fontWeight="regular" sx={{ color: text.main }}>
              Don&apos;t have an account?&nbsp;
              <SuiTypography
                variant="button"
                fontWeight="medium"
                sx={{
                  color: dark.main,
                  cursor: "pointer"
                }}
                onClick={toggleScreen}>
                Sign up
              </SuiTypography>
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </DialogContent>
    </Box>
  );
};

export default SignInFields;
