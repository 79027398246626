import axios from "services/local/api";

// endpoint
import { getSearchedDataEp } from "services/local/endpoints";

const getSearchData = async (date: string) => {
  const res =  await axios.get(getSearchedDataEp, {
      params: {
        search_date: date,
      }
    });

    return res.data?.search_results;
};

export default getSearchData;
