import React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

// @mui material components
import { Box } from "@mui/material";

// utils
import { _reorderArr } from "utils/helper/_reorderArr";

type DraggComp_withId = {
  id: string;
  reactComp: React.ReactNode;
};

interface DraggableCom_PT {
  compList: DraggComp_withId[];
}

interface dragHandleContext_T {
  dragHandleProps: any;
}

// context
export const dragHandleContext = React.createContext<dragHandleContext_T>({
  dragHandleProps: {}
});
dragHandleContext.displayName = "dragHandleContext";

const DraggableCom = ({ compList }: DraggableCom_PT) => {
  // use States
  const [list, setList] = React.useState(compList);

  React.useEffect(() => {
    setList(compList);
    return () => setList([]);
  }, [compList]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;
    const newTablesOrder = _reorderArr(list, source.index, destination.index);
    setList(newTablesOrder);
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    marginBottom: `0.7rem`,
    ...draggableStyle
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {list.map(({ id, reactComp }, index) => {
              return (
                <Draggable key={id} draggableId={id} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                      <dragHandleContext.Provider value={{ dragHandleProps: provided.dragHandleProps }}>
                        {reactComp}
                      </dragHandleContext.Provider>
                    </Box>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableCom;
