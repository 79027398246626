import React from "react";
import { TableContext } from ".";
import { useDispatch } from "react-redux";
import { rowBaseData } from "store/slices/rightPanels/getRowData";

// @mui imports
import { Box, TableRow, Checkbox, useTheme } from "@mui/material";

// components
import { SuiBox, SuiTypography, ShowLoader } from "components";

import Wrapper from "extraComp/table/MsgWrapper";

// base styles
import borders from "assets/theme/base/borders";

// table helper
import { CalcPadding, handleCheckRow, handleSelectRow, isSelected, stableSort, getComparator, DisplayTableData } from "./helper";

// utils
import { _newWindowLink } from "utils/helper/_newWindowLink";

// types
import { sharedTableData_T, row_PT } from "./types";

const TableBody = () => {
  const theme = useTheme();
  const { borderWidth } = borders;

  const { columns, data, checked, setChecked, selectedRow, setSelectRow, order, orderBy, rowsPerPage, page } = React.useContext(
    TableContext
  ) as sharedTableData_T;
  const dispatch = useDispatch();

  const handleRowChecked = (newChecked: readonly string[]) => setChecked(newChecked);

  const handleRowSelected = (newSelected: readonly string[], row: row_PT) => {
    setSelectRow(newSelected);
    dispatch(rowBaseData(row));
  };

  const handleURL = (url: string) => _newWindowLink(url);

  const renderRows = stableSort(data.rows, getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row: row_PT) => {
      const rowKey = `rowId_${row.id}`;
      const isRowSelected = isSelected(rowKey, selectedRow);
      const isRowChecked = isSelected(rowKey, checked);

      const tableRow = columns.map(({ dataKey, align, url = false, isBoolean = false, limitLength = 0 }, colKey) => {
        const { pl, pr } = CalcPadding(columns, colKey);

        return (
          <SuiBox
            key={colKey}
            component="td"
            pb={0.3}
            pl={align === "left" ? pl : 3}
            pr={align === "right" ? pr : 3}
            textAlign={align}>
            {colKey === 0 && (
              <Checkbox checked={isRowChecked} onChange={() => handleCheckRow(rowKey, checked, handleRowChecked)} />
            )}

            <SuiTypography
              variant="caption"
              color={url ? "primary" : "secondary"}
              sx={{ display: "inline-block", width: "100%" }}
              onClick={!url ? () => handleSelectRow(rowKey, selectedRow, handleRowSelected, row) : () => handleURL(row[dataKey])}>
              <DisplayTableData value={row[dataKey]} isBoolean={isBoolean} limitLength={limitLength} />
            </SuiTypography>
          </SuiBox>
        );
      });

      return (
        <TableRow
          key={rowKey}
          selected={isRowSelected}
          sx={{
            cursor: "pointer",
            "&:not(:last-of-type)": {
              borderBottom: `${borderWidth[1]} solid ${theme.palette.grey[300]}`
            }
          }}>
          {tableRow}
        </TableRow>
      );
    });

  return (
    <Box component="tbody">
      {React.useMemo(() => {
        return data.load && !data.error ? (
          <Wrapper colSpan={columns.length}>
            <ShowLoader />
          </Wrapper>
        ) : data.rows.length > 0 ? (
          renderRows
        ) : (
          <Wrapper colSpan={columns.length}>
            <SuiTypography variant="caption" color={"secondary"} fontWeight="medium">
              No Data Found
            </SuiTypography>
          </Wrapper>
        );
      }, [columns, renderRows, data])}
    </Box>
  );
};

export default TableBody;
