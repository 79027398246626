import React from "react";

// components
import { BasicModal, SuiBox, SuiTypography, SuiButton } from "components";

// mui imports
import { DialogContent, Box, Slider } from "@mui/material";

interface userProfileModal_PT {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const marks = [
  {
    value: 0,
    label: "0"
  },

  {
    value: 100,
    label: "100"
  }
];

const UserPrefrenceModal = ({ open, setOpen }: userProfileModal_PT) => {
  return (
    <BasicModal modalName="UserPrefrence" open={open} onClose={() => null} maxWidth="lg">
      <SuiBox variant="gradient" bgColor="dark">
        <DialogContent>
          <SuiTypography variant="body2" color="light" fontWeight="bold">
            User Prefrences
          </SuiTypography>

          {/* form content */}
          <Box mt={1} ml={0.5}>
            <Box mt={2} display="flex">
              <SuiTypography component="label" variant="nav_head" color="light" sx={{ pt: "0.8rem" }}>
                Relevance Score Threshold
              </SuiTypography>
              <Box width={200} mx={2}>
                <Slider defaultValue={30} marks={marks} valueLabelDisplay="on" sx={{ mb: 0 }} />
              </Box>
            </Box>

            {/* <Box display="flex" alignItems="center">
              <SuiTypography component="label" variant="nav_head" color="light">
                Default Layout On Start
              </SuiTypography>
              <Box ml={1}>
                <Checkbox />
              </Box>
            </Box> */}

            {/* buttons */}
            <Box
              mt={2.5}
              display={"flex"}
              justifyContent="flex-end"
              sx={{
                "& button:not(:first-of-type)": {
                  ml: 1.5
                }
              }}>
              <SuiButton color="light" size="small" circular onClick={() => setOpen(false)}>
                Cancel
              </SuiButton>
              <SuiButton color="light" size="small" circular>
                Save
              </SuiButton>
            </Box>
          </Box>
        </DialogContent>
      </SuiBox>
    </BasicModal>
  );
};

export default UserPrefrenceModal;
