import { useMemo } from "react";

// react-chartjs-2 components
import { Pie } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// components
import { SuiBox, SuiTypography } from "components";

// PieChart configurations
import configs from "extraComp/charts/pieChart/configs";

interface pieChart_types {
  title?: string;
  description?: string | React.ReactNode;
  height?: string | number;
  chart?: {
    labels: string[];
    datasets: any;
  };
}

function PieChart({ title = "", description = "", height = "19.125rem", chart }: pieChart_types) {
  const { data, options } = configs(chart!.labels || [], chart!.datasets || {});

  const renderChart = (
    <SuiBox p={2}>
      {title || description ? (
        <SuiBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <SuiBox mb={1}>
              <SuiTypography variant="h6">{title}</SuiTypography>
            </SuiBox>
          )}
          <SuiBox mb={2}>
            <SuiTypography component="div" variant="button" fontWeight="regular" color="text">
              {description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      ) : null}
      {useMemo(
        () => (
          <SuiBox height={height}>
            <Pie data={data} options={options} />
          </SuiBox>
        ),
        [data, options, height]
      )}
    </SuiBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

export default PieChart;
