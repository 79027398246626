import { Palette } from "assets/theme/base/colorsT";

//  React base styles
import borders, { makeBorderColor } from "assets/theme/base/borders";

//  React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderWidth } = borders;
const tableCell = (palette: Palette) => {
  const borderColor = makeBorderColor(palette);

  return {
    styleOverrides: {
      root: {
        padding: `${pxToRem(12)} ${pxToRem(16)}`,
        borderBottom: `${borderWidth[1]} solid ${borderColor}`
      }
    }
  };
};

export default tableCell;
