// types
import { PaletteMode } from "@mui/material";
import { Palette, StaticColors } from "assets/theme/base/colorsT";

const staticColors: StaticColors = {
  darkBlue: "#25274d",
  darkGray: "#464866",
  lightGray: "#AAABB8",
  cyan: "#2e9cca",
  dimBlue: "#2964ba",
  white: "#fff",
  green: "#04AA6D"
};

const colors: Palette = {
  background: {
    default: "#f8f9fa"
  },

  text: {
    main: "#67748e",
    focus: "#67748e"
  },

  transparent: {
    main: "transparent",
    focus: "transparent"
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff"
  },

  black: {
    light: "#141414",
    main: "#000000",
    focus: "#000000"
  },

  primary: {
    main: "#2152ff",
    focus: "#3A65FF"
  },

  secondary: {
    main: "#8392ab",
    focus: "#96a2b8"
  },

  info: {
    main: "#17c1e8",
    focus: "#3acaeb"
  },

  success: {
    main: "#35F661",
    focus: "#74FF94"
  },

  warning: {
    main: "#fbcf33",
    focus: "#fcd652"
  },

  error: {
    main: "#ea0606",
    focus: "#c70505"
  },

  light: {
    main: "#e9ecef",
    focus: "#e9ecef"
  },

  dark: {
    main: "#344767",
    focus: "#344767"
  },

  grey: {
    100: "#f8f9fa",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529"
  },

  gradients: {
    primary: {
      main: "#7928ca",
      state: "#ff0080"
    },

    secondary: {
      main: "#627594",
      state: "#a8b8d8"
    },

    info: {
      main: "#2152ff",
      state: "#21d4fd"
    },

    success: {
      main: "#17ad37",
      state: "#98ec2d"
    },

    warning: {
      main: "#f53939",
      state: "#fbcf33"
    },

    error: {
      main: "#ea0606",
      state: "#ff667c"
    },

    light: {
      main: "#ced4da",
      state: "#ebeff4"
    },

    dark: {
      main: "#141727",
      state: "#3a416f"
    }
  },

  badgeColors: {
    primary: {
      background: "#f883dd",
      text: "#a3017e"
    },

    secondary: {
      background: "#e4e8ed",
      text: "#5974a2"
    },

    info: {
      background: "#abe9f7",
      text: "#08a1c4"
    },

    success: {
      background: "#cdf59b",
      text: "#67b108"
    },

    warning: {
      background: "#fef5d3",
      text: "#fbc400"
    },

    error: {
      background: "#fc9797",
      text: "#bd0000"
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de"
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a"
    }
  },

  inputColors: {
    borderColor: { main: "#d2d6da", focus: "#35d1f5" },
    boxShadow: "#81e3f9",
    error: "#fd5c70",
    success: "#66d432"
  },

  sliderColors: {
    thumb: { borderColor: "#d9d9d9" }
  },

  circleSliderColors: {
    background: "#d3d3d3"
  },

  tabs: {
    indicator: { boxShadow: "#ddd" }
  },

  staticColors
};

export default colors;

export const makeColors = (mode: PaletteMode = "light") => {
  const colors =
    mode === "light"
      ? {
          background: {
            default: "#f8f9fa"
          },

          text: {
            main: "#67748e",
            focus: "#67748e"
          },

          transparent: {
            main: "transparent",
            focus: "transparent"
          },

          white: {
            main: "#ffffff",
            focus: "#ffffff"
          },

          black: {
            light: "#141414",
            main: "#000000",
            focus: "#000000"
          },

          primary: {
            main: "#2152ff",
            focus: "#3A65FF"
          },

          secondary: {
            main: "#8392ab",
            focus: "#96a2b8"
          },

          info: {
            main: "#17c1e8",
            focus: "#3acaeb"
          },

          success: {
            main: "#35F661",
            focus: "#74FF94"
          },

          warning: {
            main: "#fbcf33",
            focus: "#fcd652"
          },

          error: {
            main: "#ea0606",
            focus: "#c70505"
          },

          light: {
            main: "#e9ecef",
            focus: "#e9ecef"
          },

          dark: {
            main: "#344767",
            focus: "#344767"
          },

          grey: {
            100: "#f8f9fa",
            200: "#e9ecef",
            300: "#dee2e6",
            400: "#ced4da",
            500: "#adb5bd",
            600: "#6c757d",
            700: "#495057",
            800: "#343a40",
            900: "#212529"
          },

          gradients: {
            primary: {
              main: "#7928ca",
              state: "#ff0080"
            },

            secondary: {
              main: "#627594",
              state: "#a8b8d8"
            },

            info: {
              main: "#2152ff",
              state: "#21d4fd"
            },

            success: {
              main: "#17ad37",
              state: "#98ec2d"
            },

            warning: {
              main: "#f53939",
              state: "#fbcf33"
            },

            error: {
              main: "#ea0606",
              state: "#ff667c"
            },

            light: {
              main: "#ced4da",
              state: "#ebeff4"
            },

            dark: {
              main: "#141727",
              state: "#3a416f"
            }
          },

          badgeColors: {
            primary: {
              background: "#f883dd",
              text: "#a3017e"
            },

            secondary: {
              background: "#e4e8ed",
              text: "#5974a2"
            },

            info: {
              background: "#abe9f7",
              text: "#08a1c4"
            },

            success: {
              background: "#cdf59b",
              text: "#67b108"
            },

            warning: {
              background: "#fef5d3",
              text: "#fbc400"
            },

            error: {
              background: "#fc9797",
              text: "#bd0000"
            },

            light: {
              background: "#ffffff",
              text: "#c7d3de"
            },

            dark: {
              background: "#8097bf",
              text: "#1e2e4a"
            }
          },

          inputColors: {
            borderColor: { main: "#d2d6da", focus: "#35d1f5" },
            boxShadow: "#81e3f9",
            error: "#fd5c70",
            success: "#66d432"
          },

          sliderColors: {
            thumb: { borderColor: "#d9d9d9" }
          },

          circleSliderColors: {
            background: "#d3d3d3"
          },

          tabs: {
            indicator: { boxShadow: "#ddd" }
          }
        }
      : {
          background: {
            default: "#111825"
          },

          text: {
            main: "#A3AAAE",
            focus: "#979ea3"
          },

          transparent: {
            main: "transparent",
            focus: "transparent"
          },

          white: {
            main: "#1a212f",
            focus: "#1a212f"
          },

          black: {
            light: "#141414",
            main: "#000000",
            focus: "#000000"
          },

          primary: {
            main: "#2152ff",
            focus: "#3A65FF"
          },

          secondary: {
            main: "#8392ab",
            focus: "#96a2b8"
          },

          info: {
            main: "#17c1e8",
            focus: "#3acaeb"
          },

          success: {
            main: "#35F661",
            focus: "#74FF94"
          },

          warning: {
            main: "#fbcf33",
            focus: "#fcd652"
          },

          error: {
            main: "#f53b21",
            focus: "#e43a22"
          },

          light: {
            main: "#e9ecef",
            focus: "#e9ecef"
          },

          dark: {
            main: "#F9F6EF",
            focus: "#f1eee7"
          },

          grey: {
            100: "#f8f9fa",
            200: "#e9ecef",
            300: "#353c4b",
            400: "#ced4da",
            500: "#adb5bd",
            600: "#6c757d",
            700: "#495057",
            800: "#343a40",
            900: "#212529"
          },

          gradients: {
            primary: {
              main: "#7928ca",
              state: "#ff0080"
            },

            secondary: {
              main: "#627594",
              state: "#a8b8d8"
            },

            info: {
              main: "#2152ff",
              state: "#21d4fd"
            },

            success: {
              main: "#17ad37",
              state: "#98ec2d"
            },

            warning: {
              main: "#f53939",
              state: "#fbcf33"
            },

            error: {
              main: "#ea0606",
              state: "#ff667c"
            },

            light: {
              main: "#ced4da",
              state: "#ebeff4"
            },

            dark: {
              main: "#17162a",
              state: "#00A1AB"
            }
          },

          badgeColors: {
            primary: {
              background: "#f883dd",
              text: "#a3017e"
            },

            secondary: {
              background: "#e4e8ed",
              text: "#5974a2"
            },

            info: {
              background: "#abe9f7",
              text: "#08a1c4"
            },

            success: {
              background: "#cdf59b",
              text: "#67b108"
            },

            warning: {
              background: "#fef5d3",
              text: "#fbc400"
            },

            error: {
              background: "#fc9797",
              text: "#bd0000"
            },

            light: {
              background: "#ffffff",
              text: "#c7d3de"
            },

            dark: {
              background: "#8097bf",
              text: "#1e2e4a"
            }
          },

          inputColors: {
            borderColor: { main: "#d2d6da", focus: "#35d1f5" },
            boxShadow: "#81e3f9",
            error: "#fd5c70",
            success: "#66d432"
          },

          sliderColors: {
            thumb: { borderColor: "#d9d9d9" }
          },

          circleSliderColors: {
            background: "#d3d3d3"
          },

          tabs: {
            indicator: { boxShadow: "#ddd" }
          }
        };

  return {
    ...colors,
    staticColors
  } as Palette;
};
