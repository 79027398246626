import React, { forwardRef } from "react";

// Custom styles for SuiButton
import SuiButtonRoot from "./SuiButtonRoot";

interface Sui_btn_types {
  size?: "medium" | "large" | "small";
  variant?: "contained" | "text" | "outlined" | "gradient";
  color?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  circular?: boolean;
  iconOnly?: boolean;
  setFixedColor?: "white" | "dark" | boolean;
  children?: React.ReactNode;

  // other props
  [rest: string]: any;
}

const SuiButton = forwardRef(
  (
    {
      color = "white",
      variant = "contained",
      size = "medium",
      circular = false,
      iconOnly = false,
      setFixedColor = false,
      children,
      ...rest
    }: Sui_btn_types,
    ref: any
  ) => (
    <SuiButtonRoot
      {...rest}
      ref={ref}
      color="primary"
      variant={variant === "gradient" ? "contained" : variant}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly, setFixedColor }}>
      {children}
    </SuiButtonRoot>
  )
);

export default SuiButton;
