import React from "react";
import { useSelector } from "react-redux";
import { TableContext } from ".";
import { UseAppContext } from "context/App";
import { dragHandleContext } from "extraComp/draggable";

// @mui imports
import { Box } from "@mui/material";

// components
import { SuiTypography } from "components";

// icons
import { BsArrowsMove, BsFullscreen, BsDownload } from "react-icons/bs";

//colors
import colors from "assets/theme/base/colors";

import { CSVLink } from "react-csv";

// table
import CustomizedTable from "extraComp/table";
import { strongTableConfig, weekTableConfig, noMatchesTableConfig } from "utils/tableConfig";
import strongMatchesData from "json/table_data_strong_match.json";
import weekMatchesData from "json/table_data_weak_matches.json";
import noMatchesData from "json/table_data_no_matches.json";
import { csvExportHeader } from "utils/constants";

// types
import { sharedTableData_T } from "./types";

// interface
import { allSlices_T } from "store/slices";
import { matchesData_T } from "store/slices/searchedData/SearchData";
import { tableData } from "extraComp/table/types";

interface headerOption_T {
  dataCy?: string;
}

const HeaderOptions = ({ dataCy }: headerOption_T) => {
  const searchedData = useSelector((s: allSlices_T) => s.searchedData);
  const { data, load, error } = searchedData as matchesData_T;

  const { text, info } = colors;

  const sharedTableData: sharedTableData_T | null = React.useContext(TableContext);
  const { dragHandleProps } = React.useContext(dragHandleContext);
  const { fullscreenComp, setFullscreenComp } = UseAppContext();

  const commonStyles = {
    display: "inherit",
    px: 0.6,
    color: text.main,
    cursor: "pointer",
    transition: "color .5s",
    "&:hover, &:focus": {
      color: info.main
    }
  };

  const handleMaximize = () => {
    let tableCols = strongTableConfig,
      TABLE_DATA: tableData =
        process.env.REACT_APP_USE_API_DATA === "0"
          ? {
              rows: strongMatchesData,
              load: false,
              error: false
            }
          : {
              rows: [],
              load: false,
              error: false
            };
    if (sharedTableData!.label === "Weak Matches") {
      tableCols = weekTableConfig;
      TABLE_DATA =
        process.env.REACT_APP_USE_API_DATA === "0"
          ? {
              rows: weekMatchesData,
              load: false,
              error: false
            }
          : {
              rows: [],
              load: false,
              error: false
            };
    } else if (sharedTableData!.label === "No Matches") {
      tableCols = noMatchesTableConfig;
      TABLE_DATA =
        process.env.REACT_APP_USE_API_DATA === "0"
          ? {
              rows: noMatchesData,
              load: false,
              error: false
            }
          : {
              rows: data.noData,
              load: load,
              error: error
            };
    }
    const Comp = <CustomizedTable label={sharedTableData!.label} columns={tableCols} data={TABLE_DATA} />;
    setFullscreenComp(Comp);
  };

  return (
    <Box mx={0.9} py={0.7} display="flex" justifyContent="space-between" alignItems="center" data-cy={`${dataCy}_header`}>
      <SuiTypography variant="nav_head" color="text">
        {sharedTableData!.label}
      </SuiTypography>

      {/* icons */}
      <Box fontSize={"0.92rem"} display="flex">
        <Box
          component={"span"}
          sx={{ ...commonStyles, display: fullscreenComp ? "none" : "" }}
          title="Maximize"
          data-cy={`${dataCy}_max`}
          onClick={handleMaximize}>
          <BsFullscreen />
        </Box>

        <Box
          sx={commonStyles}
          title="Export"
          component={CSVLink}
          headers={csvExportHeader}
          data={sharedTableData!.data.rows}
          filename={`${sharedTableData!.label}_data`}
          data-cy={`${dataCy}_export`}>
          <BsDownload />
        </Box>
        <Box component={"span"} {...dragHandleProps} sx={{ ...commonStyles, display: fullscreenComp ? "none" : "" }} title="Drag">
          <BsArrowsMove />
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderOptions;
