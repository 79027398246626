import React from "react";

// components
import { BasicModal, SuiBox } from "components";

// modal content
import SignInContent from "./SignIn";
import OTPContent from "./OTP";
import SignUpContent from "./SignUp";

// static colors
import colors from "assets/theme/base/colors";
import { DashboardsEnums } from "enums/dashboards.enum";

interface auth_modal_PT {
  open: boolean;
  setOpen: any;
  navigateTo: DashboardsEnums
}

const AuthModal = ({ open, setOpen, navigateTo }: auth_modal_PT) => {
  const { white } = colors;

  //usestates
  const [otpScreen, setOtpScreen] = React.useState<boolean>(false);
  const [signUpScreen, setSignUpScreen] = React.useState<boolean>(false);
  const [OTP, setOTP] = React.useState<number | undefined>(undefined);

  // OTP effect
  React.useEffect(() => {
    if (OTP) setOtpScreen(true);
  }, [OTP]);

  const toggleScreen = () => setSignUpScreen(!signUpScreen);

  return (
    <BasicModal modalName="auth" open={open} onClose={() => setOpen(!open)} data-cy="auth_modal">
      <SuiBox p={{ xs: 0.5, sm: 1.5 }} sx={{ background: white.main }}>
        {!signUpScreen ? (
          !otpScreen ? (
            <SignInContent toggleScreen={toggleScreen} setOtp={setOTP} />
          ) : (
            <OTPContent otp={OTP} navigateTo={navigateTo} />
          )
        ) : (
          <SignUpContent toggleScreen={toggleScreen} />
        )}
      </SuiBox>
    </BasicModal>
  );
};

export default AuthModal;
