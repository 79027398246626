import React from "react";

// OTP field
import { OtpInput } from "extractedPackages";

import colors from "assets/theme/base/colors";

interface OTPInput_PT {
  value?: any;
  onChange?: any;

  // other props
  [rest: string]: any;
}

const OTPInput = ({ value, onChange, ...rest }: OTPInput_PT) => {
  const { dark } = colors || {};

  return (
    <OtpInput
      value={value}
      onChange={onChange}
      numInputs={4}
      isInputNum
      containerStyle={{ justifyContent: "center" }}
      inputStyle={{ fontSize: "1.25rem", width: "2rem", height: "2rem", borderRadius: "0.3rem" }}
      focusStyle={{ borderColor: dark.main }}
      separator={<span>&nbsp;-&nbsp;</span>}
      shouldAutoFocus
      {...rest}
    />
  );
};

export default OTPInput;
