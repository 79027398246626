import { forwardRef } from "react";

// Custom styles for SuiBox
import SuiBoxRoot from "components/SuiBox/SuiBoxRoot";

interface SuiBox_PT {
  variant?: "contained" | "gradient";
  bgColor?: string;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
  grad_angle?: number;

  // other props
  [rest: string]: any;
}

const SuiBox = forwardRef(
  (
    {
      variant = "contained",
      bgColor = "transparent",
      color = "dark",
      opacity = 1,
      borderRadius = "0",
      shadow = "none",
      grad_angle,
      ...rest
    }: SuiBox_PT,
    ref: any
  ) => <SuiBoxRoot {...rest} ref={ref} ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, grad_angle }} />
);

export default SuiBox;
