import React, { forwardRef } from "react";

// Custom styles for SuiTypography
import SuiTypographyRoot from "./SuiTypographyRoot";

interface SuiTypography_PT {
  color?: "dark" | "inherit" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "text" | "white";
  fontWeight?: false | "light" | "regular" | "medium" | "bold";
  textTransform?: "none" | "capitalize" | "uppercase" | "lowercase";
  verticalAlign?: "unset" | "baseline" | "sub" | "super" | "text-top" | "text-bottom" | "middle" | "top" | "bottom";
  textGradient?: boolean;
  opacity?: number;
  children?: React.ReactNode;

  // other props
  [rest: string]: any;
}

const SuiTypography = forwardRef(
  (
    {
      color = "dark",
      fontWeight = false,
      textTransform = "none",
      verticalAlign = "unset",
      textGradient = false,
      opacity = 1,
      children,
      ...rest
    }: SuiTypography_PT,
    ref: any
  ) => (
    <SuiTypographyRoot
      {...rest}
      ref={ref}
      ownerState={{ color, textTransform, verticalAlign, fontWeight, opacity, textGradient }}
    >
      {children}
    </SuiTypographyRoot>
  )
);

export default SuiTypography;
