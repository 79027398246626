import React from "react";
import Sticky from "react-stickynode";

// static components
import LogoBar from "layouts/static/components/LogoBar";

import { Box } from "@mui/material";

// static colors
import colors from "assets/theme/base/colors";

interface layout_types {
  children?: React.ReactNode;
}
const StaticLayout = ({ children }: layout_types) => {
  const { white } = colors;

  const [stickyState, setStickyState] = React.useState(false);

  return (
    <Box sx={{ position: "relative", background: white.main }}>
      <Sticky onStateChange={() => setStickyState(!stickyState)} top={0} innerZ={99}>
      <LogoBar isSticky={stickyState} />
      </Sticky>
      {children}
    </Box>
  );
};

export default StaticLayout;
