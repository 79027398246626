import React from "react";
import { useForm, Controller } from "react-hook-form";

// components
import { SuiTypography, SuiInput, DropDown } from "components";

// mui imports
import { Box, Checkbox, FormControl } from "@mui/material";

//  dropdown options
import { entityStatusOptions, workStatusOptions } from "utils/constants";

type dropDown_T = {
  value: string;
  label: string;
};

interface entity_Form {
  firstName: string;
  middleName: string;
  lastName: string;
  companyName: string;
  city: string;
  postalCode: string;
  country: string;
  address: string;
  alternativeAddress: string;
  workStatus: dropDown_T[];
  entityStatus: dropDown_T[];
  activeInSearches: boolean;
  isCompany: boolean;
}

const EntityForm = () => {
  const { control, register, watch } = useForm<entity_Form>();

  return (
    <>
      <Box display="flex" alignItems="center">
        {watch("isCompany") ? (
          <SuiInput placeholder="Company Name" {...register("companyName")} />
        ) : (
          <>
            <SuiInput placeholder="Firstname" {...register("firstName")} />
            <SuiInput placeholder="Middlename" sx={{ ml: 1 }} {...register("middleName")} />
            <SuiInput placeholder="Lastname" sx={{ ml: 1 }} {...register("lastName")} />
          </>
        )}
      </Box>

      <Box mt={1.5} display="flex" alignItems="center">
        <SuiInput placeholder="City" {...register("city")} />
        <SuiInput placeholder="Postal Code" sx={{ ml: 1 }} {...register("postalCode")} />
        <SuiInput placeholder="Country" sx={{ ml: 1 }} {...register("country")} />
      </Box>

      <Box mt={1.5} width="100%">
        <SuiInput placeholder="Address" {...register("address")} />
      </Box>

      <Box mt={1.5} width="100%">
        <SuiInput placeholder="Address Line 2" {...register("alternativeAddress")} />
      </Box>

      <Box mt={1.8} display={"flex"} alignItems="center">
        <SuiTypography variant="nav_head" color="light">
          Work Status
        </SuiTypography>
        <FormControl sx={{ ml: 1.3, width: "12rem" }} size="small">
          <Controller
            control={control}
            name="workStatus"
            render={({ field: { onChange, value } }) => (
              <DropDown
                optionsList={workStatusOptions}
                placeholder="Select Status"
                isSearchable={false}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </FormControl>
      </Box>

      <Box mt={1.5} display={"flex"} alignItems="center">
        <SuiTypography variant="nav_head" color="light">
          Entity Status
        </SuiTypography>
        <FormControl sx={{ ml: 0.8, width: "12rem" }} size="small">
          <Controller
            control={control}
            name="entityStatus"
            render={({ field: { onChange, value } }) => (
              <DropDown
                optionsList={entityStatusOptions}
                placeholder="Select Status"
                isSearchable={false}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </FormControl>
      </Box>

      <Box mt={1.5} display="flex" alignItems="center">
        <SuiTypography variant="nav_head" color="light">
          Active in searches
        </SuiTypography>
        <Box ml={1}>
          <Checkbox {...register("activeInSearches")} />
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <SuiTypography variant="nav_head" color="light">
          Company
        </SuiTypography>
        <Box ml={1}>
          <Checkbox {...register("isCompany")} />
        </Box>
      </Box>
    </>
  );
};

export default EntityForm;
