import { column_PT } from "extraComp/table/types";

export const strongTableConfig: column_PT[] = [
  { dataKey: "entity_name", name: "Name", align: "left", limitLength: 13 },
  { dataKey: "risk_score", name: "Relevance Score", align: "center" },
  { dataKey: "is_new", name: "New", align: "center", isBoolean: { trueVal: "Yes", falseVal: "No" } },
  { dataKey: "status", name: "Match Status", align: "center" },
  { dataKey: "work_status", name: "Work Status", align: "center" },
  { dataKey: "link", name: "Link", align: "left", url: true }
];

export const weekTableConfig: column_PT[] = [
  { dataKey: "entity_name", name: "Name", align: "left", limitLength: 13 },
  { dataKey: "risk_score", name: "Relevance Score", align: "center" },
  { dataKey: "is_new", name: "New", align: "center", isBoolean: { trueVal: "Yes", falseVal: "No" } },
  { dataKey: "status", name: "Match Status", align: "center" },
  { dataKey: "work_status", name: "Work Status", align: "center" },
  { dataKey: "link", name: "Link", align: "left", url: true }
];

export const noMatchesTableConfig: column_PT[] = [
  { dataKey: "entity_name", name: "Name", align: "left", limitLength: 13 },
  { dataKey: "risk_score", name: "Relevance Score", align: "center" },
  { dataKey: "is_new", name: "New", align: "center", isBoolean: { trueVal: "Yes", falseVal: "No" } },
  { dataKey: "status", name: "Match Status", align: "center" },
  { dataKey: "work_status", name: "Work Status", align: "center" }
];
