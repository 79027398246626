import React from "react";
import { UseAppContext } from "context/App";

// @mui material components
import { Box, Tooltip } from "@mui/material";

// custom components
import { SuiTypography, SuiButton, SuiBox } from "components";

//colors
import colors from "assets/theme/base/colors";

// icons
import { BsClock, BsHourglassSplit, BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { MdFiberNew } from "react-icons/md";

// types
import { relvance_T } from "store/slices/rightPanels/getRowData";

interface UserInfo_PT {
  relvance: relvance_T;
}

const UserInfo = ({ relvance }: UserInfo_PT) => {
  const { light, warning, info, success, error } = colors;
  const { setActionModal } = UseAppContext();

  // decide icon & color
  let SelectedIcon: React.ReactNode, selectedColor: string;

  switch (relvance.status.value) {
    case "New":
      SelectedIcon = <MdFiberNew color={light.main} />;
      selectedColor = "light";
      break;
    case "Pending":
      SelectedIcon = <BsClock color={warning.main} />;
      selectedColor = "warning";
      break;
    case "In Progress":
      SelectedIcon = <BsHourglassSplit color={info.main} />;
      selectedColor = "info";
      break;
    case "Completed":
      SelectedIcon = <BsCheckCircleFill color={success.main} />;
      selectedColor = "success";
      break;
    case "Cancelled":
      SelectedIcon = <BsXCircleFill color={error.main} />;
      selectedColor = "error";
      break;
    default:
      SelectedIcon = null;
      selectedColor = "";
  }

  return (
    <SuiBox
      variant="gradient"
      bgColor="dark"
      display="flex"
      alignItems="center"
      justifyContent={"space-between"}
      px={{ xs: 2, md: 5 }}
      py={2}>
      <Box>
        <SuiTypography variant="body2" color="light" fontWeight="bold">
          {relvance.entity_name}
          <SuiTypography component="span" variant="button" ml={1} color={selectedColor}>
            {`( ${relvance.score.value} )`}
          </SuiTypography>
        </SuiTypography>
        <SuiTypography variant="body2" mt={1} color="light">
          {relvance.status.entity_name}
          <Tooltip title={relvance.status.value}>
            <Box
              component="span"
              fontSize={"1.25rem"}
              ml={1}
              sx={{
                display: "inline-flex",
                verticalAlign: "middle",
                pb: "0.2rem"
              }}>
              {SelectedIcon}
            </Box>
          </Tooltip>
        </SuiTypography>
      </Box>
      <Box>
        <SuiButton color="light" size="small" circular onClick={() => setActionModal(true)} data-cy="open_actionModal">
          Action
        </SuiButton>
      </Box>
    </SuiBox>
  );
};

export default UserInfo;
