import React, { useEffect } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// pages
import { HomePage, DashboardPage, ConsultingPage} from "layouts";

export default function AllRoutes() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document!.scrollingElement!.scrollTop = 0;
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/regulatory" element={<HomePage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/consulting" element={<ConsultingPage />} />
      <Route path="*" element={<p>No page Found</p>} />
    </Routes>
  );
}
