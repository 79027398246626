import React from "react";
import { useForm } from "react-hook-form";

// components
import { SuiTypography, SuiInput, SuiButton } from "components";

// mui imports
import { Box } from "@mui/material";

import { CSVLink } from "react-csv";

// export data
import strongMatchesData from "json/table_data_strong_match.json";
import weakMatchesData from "json/table_data_weak_matches.json";
import noMatchesData from "json/table_data_no_matches.json";

import { csvExportHeader } from "utils/constants";

// types
import { tab_PT } from ".";

interface exportData_Form {
  exportName: string;
}

const ExportData = ({ handleClose }: tab_PT) => {
  const csvExportRef = React.useRef<any>(null);

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm<exportData_Form>();

  const handleExportData = () => {
    if (csvExportRef.current) csvExportRef.current.link.click();
  };

  return (
    <Box mt={2.5}>
      <SuiTypography variant="body2" color="light" fontWeight="bold" ml={0.5}>
        Export Data
      </SuiTypography>
      {/* form content */}
      <Box mt={1} component="form" role="form" onSubmit={handleSubmit(handleExportData)}>
        <Box display="flex" alignItems={"center"}>
          <SuiTypography component="label" variant="nav_head" color="light">
            Please enter filename
          </SuiTypography>
          <Box ml={1.2} flex={1}>
            <SuiInput
              type="text"
              placeholder="fileName*"
              {...register("exportName", {
                required: "Field is required"
              })}
              error={errors.exportName?.message}
            />
          </Box>
        </Box>

        {/* buttons */}
        <Box
          mt={2.5}
          display={"flex"}
          justifyContent="flex-end"
          sx={{
            "& button:not(:first-of-type)": {
              ml: 1.5
            }
          }}>
          <SuiButton color="light" size="small" circular onClick={handleClose}>
            Cancel
          </SuiButton>
          <SuiButton color="light" size="small" circular type="submit">
            Export
          </SuiButton>
          <Box
            ref={csvExportRef}
            sx={{ display: "none" }}
            component={CSVLink}
            headers={csvExportHeader}
            data={[...strongMatchesData, ...weakMatchesData, ...noMatchesData]}
            filename={`${watch("exportName")}_data`}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ExportData;
